import React, { useEffect, useState, useRef, useCallback } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import LoadingPage from "../LoadingPage";
import Document from "./Document";
import DocumentIcons from "./DocumentIcons";
import DocumentImage from "./DocumentImage";
import DocumentDownloadSection from "./DocumentDownloadSection";
import DocumentPage from "./DocumentPage";
import DocumentAppuiPage from "./DocumentAppuiPage";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { getAppUIValue } from "../../stores/DocumentRenderStore";
import { getSortedDocs } from "../../services/consentUtils";
import ToTopButton from "../ToTopButton/ToTopButton"

const actions = require("../../actions/Stepper");

const styles = (theme) => ({
  content: {
    /*marginTop: theme.spacing(2),*/
    padding: theme.spacing(2),
    textAlign: "left",
  },
  blocks: {
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  leftColumnWhite: {
    marginRight: "20px",
    marginLeft: "20px",
    marginTop: "20px",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    backgroundColor: "#ffffff",
    height: "100%",
  },
  rightColumnLightGray: {
    verticalAlign: "bottom",
    backgroundColor: "#f5f7f7",
  },
  document: {
    backgroundColor: "white",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "20px",
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  subTitle: {
    fontSize: "25px",
    padding: "10px 10px 20px 10px",
    textAlign: "left",
    color: "#303333",
    lineHeight: "1.2",
  },
  downloadDocument: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
  },
  container: {
    height: "600px",
  },
  scrolldiv: {
    overflowX: "hidden",
    height: "inherit",
    overflow: "auto",
  },
  h2Style: {
    fontSize: "29px",
    margin: "20px",
    textAlign: "justify",
  },
  stepperMargin: {
    marginLeft: "10px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  downloadDocumentMessage: {
    textAlign: "left",
    width: "100%",
    padding: theme.spacing(2),
  },
  marginTopSubtitle2: {
    marginTop: "40px",
  },
});

/**
 * Update stepper color once all document ar read or viewed.
 * @param {boolean} areAllDocumentsReadOrViewedState define if document constraint is completed
 */
const getStepperColor = (areAllDocumentsReadOrViewedState) => {
  return areAllDocumentsReadOrViewedState ? "success" : "info";
};

/**
 * Update document status when requirement is completed.
 * @param {*} completeDocArrayState list of all documents to be sign
 * @param {*} currentDocumentIndex current document index in the list
 * @param {boolean} isRead define if current document is read
 */
const updateDocumentStatus = (
  completeDocArrayState,
  currentDocumentIndex,
  isRead
) => {
  return completeDocArrayState.map((document, index) => {
    if (index === currentDocumentIndex) {
      document["hasBeenOpened"] = true;
      if (!document.hasBeenRead) {
        document["hasBeenRead"] = isRead;
      }
    }
    return document;
  });
};

/**
 * Update the contract reac/viewed status when requirement is completed.
 * @param {*} updatedFullArray list of document with updated read/viewed status
 */
const updateAllDocumentReadOrViewedStatus = (updatedFullArray) => {
  let allDocumentViewed = true;
  let allDocumentRead = true;
  updatedFullArray
    .filter((document) => document.requirement === "view" || document.requirement === "none")
    .forEach((toBeViewedDocument) => {
      if (!toBeViewedDocument.hasBeenOpened) {
        allDocumentViewed = false;
      }
    });
  updatedFullArray
    .filter((document) => document.requirement === "read")
    .forEach((toBeReadDocument) => {
      if (!toBeReadDocument.hasBeenRead) {
        allDocumentRead = false;
      }
    });
  return { viewed: allDocumentViewed, read: allDocumentRead };
};

/**
 * Update whole document array depending on actions perform on screen (when requirments are completed)
 * @param {*} currentDocument current document open/displayed on screen
 * @param {boolean} isLoadedFirstDoc define if the first document is loaded on screen
 * @param {boolean} areDocumentsLoaded define if all document have been loaded in state
 * @param {*} completeDocArrayState list of all documents to be sign
 * @param {boolean} areAllDocumentsReadOrViewedState define if all document's requiements are completed
 * @param {boolean} isRead define if current document is read
 * @param {{setDocumentsToSignAction: *, setAreAllDocumentsViewedAction: *, setAreAllDocumentsReadAction: *, setAreAllDocumentsReadOrViewedAction: *}} storeFunctions
 */
const updateDocumentArray = (
  currentDocument,
  isLoadedFirstDoc,
  areDocumentsLoaded,
  completeDocArrayState,
  areAllDocumentsReadOrViewedState,
  isRead,
  storeFunctions
) => {
  if (areDocumentsLoaded || isLoadedFirstDoc) {
    const currentDocumentIndex = completeDocArrayState.indexOf(currentDocument);
    const updatedFullArray = updateDocumentStatus(
      completeDocArrayState,
      currentDocumentIndex,
      isRead
    );
    storeFunctions.setDocumentsToSignAction(updatedFullArray);
    if (!areAllDocumentsReadOrViewedState) {
      const documentStatus =
        updateAllDocumentReadOrViewedStatus(updatedFullArray);
      storeFunctions.setAreAllDocumentsViewedAction(documentStatus.viewed);
      storeFunctions.setAreAllDocumentsReadAction(documentStatus.read);
      storeFunctions.setAreAllDocumentsReadOrViewedAction();
    }
  }
};

/**
 * Set current document displayed on screen.
 * @param {*} signingDoc array list of document to sign
 * @param {*} informativeDoc array list of informative documents
 * @param {React.Dispatch<React.SetStateAction<{}>>} setCurrentDoc
 */
const setCurrentDisplayedDocument = (
  signingDoc,
  informativeDoc,
  setCurrentDoc
) => {
  if ([...signingDoc, ...informativeDoc]?.length > 0) {
    setCurrentDoc([...signingDoc, ...informativeDoc][0]);
  }
};

/**
 * Update displayed document on screen on click on document name.
 * @param {*} item current opened document
 * @param {*} setCurrentDoc funtion updating current document in store
 * @param {*} completeDocArrayState list of all documents to be sign
 * @param {{areDocumentsLoaded: boolean, areAllDocumentsReadOrViewedState: boolean, isRead: boolean}} stateBooleans
 * @param {{setDocumentsToSignAction: *, setAreAllDocumentsViewedAction: *, setAreAllDocumentsReadAction: *, setAreAllDocumentsReadOrViewedAction: *}} storeFunctions
 * @param {*} scrollToTop function that init docupent vertical scroll to top
 */
const displayActionComplete = (
  item,
  setCurrentDoc,
  completeDocArrayState,
  stateBooleans,
  storeFunctions,
  scrollToTop
) => {
  setCurrentDoc(item);
  getAppUIValue(item) === true
    ? actions.displayAppuiDocMessage()
    : actions.displayDocument(item.name);
  updateDocumentArray(
    item,
    false,
    stateBooleans.areDocumentsLoaded,
    completeDocArrayState,
    stateBooleans.areAllDocumentsReadOrViewedState,
    stateBooleans.isRead,
    storeFunctions
  );
  scrollToTop();
};

/**
 * Retrieve currently displayed document index url from docImage. This is used
 * to check which document is currently displayed and inform the user.
 * @param {*} docImage list of all current document pages
 */
const getCurrentImageIndexUrl = (docImage) => {
  if (docImage.length > 0) {
    // docImage default string is like this:
    // ISIGN_SIGNING_API/api/v1/document/image?url=/dtp/frontoffice/api/v5u3/siop_bpm_sg/siop_sg/CONTEXT_URL/document/DOCUMENT_TYPE/PAGE/DB_DOC_NAME"

    // 1rst thing is to get document url
    const docImageStartIndex = docImage[0].src.indexOf("/dtp/");
    const docImageTmpLastIndex = docImage[0].src.lastIndexOf("/");
    const docImageTmpUrl = docImage[0].src.substring(
      docImageStartIndex,
      docImageTmpLastIndex
    );
    // 1rst result is like:
    // /dtp/frontoffice/api/v5u3/siop_bpm_sg/siop_sg/CONTEXT_URL/document/DOCUMENT_TYPE/PAGE

    // Now without the page element starting with '/'
    const docImageLastIndex = docImageTmpUrl.lastIndexOf("/");
    // last result is like:
    // /dtp/frontoffice/api/v5u3/siop_bpm_sg/siop_sg/CONTEXT_URL/document/DOCUMENT_TYPE

    return docImageTmpUrl.substring(0, docImageLastIndex);
  }
};

/**
 * Get current document position in documents to sign array. Return the index
 * of the current document if its index url correspond to the one of the displayed
 * docImage.
 * @param {*} stateData stored data info for current contract
 */
const getCurrentDocumentIndex = (stateData) => {
  const signingDoc = getSortedDocs(stateData.signingDoc);
  const informativeDoc = getSortedDocs(stateData.informativeDoc);
  const notAppuiDocs = getSortedDocs(
    [...signingDoc, ...informativeDoc].filter(
      (item) => getAppUIValue(item) === false
    )
  );
  let currentDocumentIndex = 0;
  if (stateData.docImage.length > 0) {
    for (let document of notAppuiDocs) {
      // default index url is like:
      // /dtp/frontoffice/api/v5u3/siop_bpm_sg/siop_sg/CONTEXT_URL/document/DOCUMENT_TYPE/index.json

      // Remove last '/index.json' part
      const indexOfLastWantedCharacter = document.index_url.lastIndexOf("/");
      const indexUrlTroncated = document.index_url.substring(
        0,
        indexOfLastWantedCharacter
      );
      // Result is like: /dtp/frontoffice/api/v5u3/siop_bpm_sg/siop_sg/CONTEXT_URL/document/DOCUMENT_TYPE
      // which correspond to the update docImage url
      if (getCurrentImageIndexUrl(stateData.docImage) === indexUrlTroncated) {
        return currentDocumentIndex;
      }
      currentDocumentIndex++;
    }
  } else {
    return currentDocumentIndex;
  }
};

/**
 * Update status of specified completeDocArray[index] document. 3 values are possible
 * here: 'complete', 'in progress' or 'inactive'. These values are used in the stepper
 * to display corresponding icon on the left part and enable next documents.
 * @param {Array[]} completeDocArray list of all documents to be sign
 * @param {number} index index of current document in completeDocArray
 * @param {"inactive" | "in progress" | "completed"} stepperStatus define the status of current document in the stepper ("inactive" | "in progress" | "completed")
 */
const setDocumentStepperStatus = (completeDocArray, index, stepperStatus) => {
  if (completeDocArray.length >= index + 1) {
    completeDocArray[index].stepperStatus = stepperStatus;
  }
};

/**
 * Update status of current and next documents depending on the current document
 * requirement.
 * @param {Array[]} completeDocArray list of all documents to be sign
 * @param {number} index index of current document in completeDocArray
 */
const setCurrentDocumentStepperStatus = (completeDocArray, index) => {
  if (completeDocArray[index].requirement === "read") {
    if (completeDocArray[index].hasBeenOpened) {
      if (completeDocArray[index].hasBeenRead) {
        // Requirement has been completed, next document is now available
        setDocumentStepperStatus(completeDocArray, index, "completed");
        setDocumentStepperStatus(completeDocArray, index + 1, "in progress");
      } else {
        setDocumentStepperStatus(completeDocArray, index, "in progress");
      }
    }
  } else if (completeDocArray[index].requirement === "view" || completeDocArray[index].requirement === "none") {
    if (completeDocArray[index].hasBeenOpened) {
      // Requirement has been completed, next document is now available
      setDocumentStepperStatus(completeDocArray, index, "completed");
      setDocumentStepperStatus(completeDocArray, index + 1, "in progress");
    }
  } else {
    setDocumentStepperStatus(completeDocArray, index, "inactive");
  }
};

/**
 * Return status of a given document to display the corresponding one in the stepper.
 * @param {Array[]} completeDocArray list of all documents to be sign
 * @param {number} index index of current document in completeDocArray
 * @param {boolean} areAllDocumentsReadOrViewedState define if all document's requiements are completed
 */
const getDocumentSrepperStatus = (
  completeDocArray,
  index,
  areAllDocumentsReadOrViewedState
) => {
  if (areAllDocumentsReadOrViewedState) {
    return "completed";
  } else {
    if (completeDocArray?.length) {
      setCurrentDocumentStepperStatus(completeDocArray, index);
      return completeDocArray[index].stepperStatus;
    } else {
      return "inactive";
    }
  }
};

/**
 * Define if previous document requirement have been completed.
 * @param {Array[]} completeDocArray list of all documents to be sign
 * @param {number} index index of current document in completeDocArray
 * @param {boolean} areAllDocumentsReadOrViewedState define if all document's requiements are completed
 */
const isPreviousDocumentAlreadyReadOrViewed = (
  completeDocArray,
  index,
  areAllDocumentsReadOrViewedState
) => {
  if (areAllDocumentsReadOrViewedState) {
    return true;
  } else {
    if (index === 0) {
      return true;
    } else if (completeDocArray?.length) {
      return completeDocArray[index - 1].stepperStatus === "completed";
    } else {
      return false;
    }
  }
};

/**
 * Define if document is appuiDocument or displayable document. In both cases,
 * it displays corresponding element on screen.
 * @param {*} item current document
 */
const displayCorrespondingDocumentElement = (item) => {
  getAppUIValue(item) === true
    ? actions.displayAppuiDocMessage()
    : actions.displayDocument(item.name);
};

/**
 * Define total height of stepper widget (60 px correspond to the size of 1 item in the stepper)
 * @param {*} notAppuiDocsSteps list of document to sign
 * @param {React.MutableRefObject<undefined>} docToSignRef ref of viewer HTML element
 */
const getSigningDocsHeight = (notAppuiDocsSteps, docToSignRef) => {
  if (notAppuiDocsSteps?.length >= 1 && notAppuiDocsSteps?.length < 10) {
    return 60 * notAppuiDocsSteps?.length;
  } else {
    return docToSignRef?.current?.clientHeight;
  }
};

function DocumentsToSign(props) {
  const { t } = useTranslation();

  const onlyAppuiDocumentsState = useStoreState(
    (state) => state.documentsToSign.onlyAppuiDocuments
  );
  const completeDocArrayState = useStoreState(
    (state) => state.documentsToSign.documentsToBeSign
  );
  const areAllDocumentsReadOrViewedState = useStoreState(
    (state) => state.documentsToSign.areAllDocumentsReadOrViewed
  );

  const [areDocumentsLoaded, setAreDocumentsLoaded] = useState(true);
  const [currentDoc, setCurrentDoc] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  let isRead = false;

  const setAreAllDocumentsViewedAction = useStoreActions(
    (action) => action.documentsToSign.setAreAllDocumentsViewed
  );
  const setAreAllDocumentsReadAction = useStoreActions(
    (action) => action.documentsToSign.setAreAllDocumentsRead
  );
  const setOnlyAppuiDocumentsAction = useStoreActions(
    (action) => action.documentsToSign.setOnlyAppuiDocuments
  );
  const setDocumentsToSignAction = useStoreActions(
    (action) => action.documentsToSign.setDocumentsToSign
  );
  const setAreAllDocumentsReadOrViewedAction = useStoreActions(
    (action) => action.documentsToSign.setAreAllDocumentsReadOrViewed
  );
  const setAllDocumentsReadOrViewedAction = useStoreActions(
    (action) => action.documentsToSign.setAllDocumentsReadOrViewed
  );
  const langState = useStoreState((state) => state.userInfo.lang);
  const storeFunctions = {
    setDocumentsToSignAction: setDocumentsToSignAction,
    setAreAllDocumentsViewedAction: setAreAllDocumentsViewedAction,
    setAreAllDocumentsReadAction: setAreAllDocumentsReadAction,
    setAreAllDocumentsReadOrViewedAction: setAreAllDocumentsReadOrViewedAction,
  };
  const stateBooleans = {
    areDocumentsLoaded: areDocumentsLoaded,
    areAllDocumentsReadOrViewedState: areAllDocumentsReadOrViewedState,
    isRead: isRead,
  };

  useEffect(() => {
    setAreAllDocumentsReadOrViewedAction();
  }, [setAreAllDocumentsReadOrViewedAction]);

  const initDocumentArray = useCallback(
    (fullArray) => {
      const initializedFullArray = fullArray.map((document) => {
        document["hasBeenOpened"] = false;
        document["hasBeenRead"] = false;
        document["stepperStatus"] = "inactive";
        return document;
      });
      setDocumentsToSignAction(initializedFullArray);
    },
    [setDocumentsToSignAction]
  );

  useEffect(() => {
    const signingDoc = getSortedDocs(props.data.stateData.signingDoc);
    const informativeDoc = getSortedDocs(props.data.stateData.informativeDoc);
    initDocumentArray([...signingDoc, ...informativeDoc]);
    setCurrentDisplayedDocument(
      props.data.stateData.signingDoc,
      props.data.stateData.informativeDoc,
      setCurrentDoc
    );
  }, [
    props.data.stateData.signingDoc,
    props.data.stateData.informativeDoc,
    initDocumentArray,
  ]);

  const { classes } = props;

  let page = <LoadingPage data={props.data} />;
  let appuipage = <LoadingPage data={props.data} />;
  let topButton = <></>;
  const docImageRef = useRef();
  const docToSignRef = useRef();
  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      // Add check when screen is zoom in (more than scrollHeight) and zoom out (less than scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight * 0.98) {
        isRead = true;
        updateDocumentArray(
          currentDoc,
          false,
          areDocumentsLoaded,
          completeDocArrayState,
          areAllDocumentsReadOrViewedState,
          isRead,
          storeFunctions
        );
      }
    }
  };
  const scrollToTop = () => {
    listInnerRef.current.scroll(0, 0);
    isRead = false;
  };
  const image = (
    <DocumentImage
      classes={classes}
      docImage={props.data.stateData.docImage}
      firstLargeFileDisableDisplay={
        props.data.stateData.firstLargeFileDisableDisplay
      }
      docToSignRef={docToSignRef}
      docImageRef={docImageRef}
      onScroll={onScroll}
      listInnerRef={listInnerRef}
      data={props.data}
      scrollPosition={props.scrollPosition}
      setAreDocumentsLoaded={setAreDocumentsLoaded}
      updateDocumentArray={updateDocumentArray}
      currentDoc={currentDoc}
      areDocumentsLoaded={areDocumentsLoaded}
      completeDocArrayState={completeDocArrayState}
      areAllDocumentsReadOrViewedState={areAllDocumentsReadOrViewedState}
      isRead={isRead}
      storeFunctions={storeFunctions}
    />
  );

  const areDownloadFilesDisplayedAction = (areDownloadFilesDisplayed) => {
    setIsExpanded(areDownloadFilesDisplayed);
  };

  if (props.data.stateData.signatureDataLoaded) {
    const signingDoc = getSortedDocs(props.data.stateData.signingDoc);
    const informativeDoc = getSortedDocs(props.data.stateData.informativeDoc);
    let notAppuiDocsSteps = [];
    let requirementArray = [];
    let documentIconsArray = [];
    const notAppuiDocs = getSortedDocs(
      [...signingDoc, ...informativeDoc].filter(
        (item) => getAppUIValue(item) === false
      )
    );
    const appuiDocs = getSortedDocs(
      [...signingDoc, ...informativeDoc].filter(
        (item) => getAppUIValue(item) === true
      )
    );

    const onlyAppuiDocumentsParam = !notAppuiDocs?.length && appuiDocs?.length;
    setOnlyAppuiDocumentsAction(onlyAppuiDocumentsParam);

    notAppuiDocs.forEach((item, index) => {
      requirementArray.push(item.requirement);
      notAppuiDocsSteps.push({
        title: (
          <Document
            key={`notAppuiDocs${index}`}
            ext={item.type}
            name={item.name}
            action={() => {
              setCurrentDoc(item);
              displayCorrespondingDocumentElement(item);
              updateDocumentArray(
                item,
                false,
                areDocumentsLoaded,
                completeDocArrayState,
                areAllDocumentsReadOrViewedState,
                isRead,
                storeFunctions
              );
            }}
            downloadAction={actions.downloadDocument.bind(null, item.index_url)}
            displayAction={() => {
              setIsExpanded(false);
              displayActionComplete(
                item,
                setCurrentDoc,
                completeDocArrayState,
                stateBooleans,
                storeFunctions,
                scrollToTop
              );
            }}
            isClickable={
              completeDocArrayState &&
              isPreviousDocumentAlreadyReadOrViewed(
                completeDocArrayState,
                index,
                areAllDocumentsReadOrViewedState
              )
            }
            requirement={requirementArray[index - 1]}
            isDisplayable={true}
          />
        ),
        status: getDocumentSrepperStatus(
          completeDocArrayState,
          index,
          areAllDocumentsReadOrViewedState
        ),
        item,
      });
      documentIconsArray.push(
        <DocumentIcons
          name={item.name}
          downloadAction={actions.downloadDocument.bind(null, item.index_url)}
          isSelected={getCurrentDocumentIndex(props.data.stateData) === index}
          status={getDocumentSrepperStatus(
            completeDocArrayState,
            index,
            areAllDocumentsReadOrViewedState
          )}
          areAllDocumentsReadOrViewed={areAllDocumentsReadOrViewedState}
          index={index}
          lastIndex={completeDocArrayState.length - 1}
          requirement={requirementArray[index - 1]}
          isClickable={
            completeDocArrayState &&
            isPreviousDocumentAlreadyReadOrViewed(
              completeDocArrayState,
              index,
              areAllDocumentsReadOrViewedState
            )
          }
        />
      );
    });
    if (appuiDocs?.length) {
      notAppuiDocsSteps.push({
        title: (
          <Document
            key={"appuiDocs"}
            ext={t("contractualDocumentation")}
            name={t("contractualDocumentation")}
            isNotDownloadable={true}
            areDownloadFilesDisplayed={areDownloadFilesDisplayedAction}
            isExpanded={isExpanded || onlyAppuiDocumentsState}
            setIsExpanded={setIsExpanded}
          />
        ),
        status: "inactive",
      });
    }

    const downloadSection = (
      <DocumentDownloadSection
        classes={classes}
        signatureUserAccessInfo={props.data.signatureUserAccessInfo}
        langState={langState}
        appuiDocs={appuiDocs}
        signingDoc={signingDoc}
        setAllDocumentsReadOrViewedAction={setAllDocumentsReadOrViewedAction}
        displayActionComplete={displayActionComplete}
        setCurrentDoc={setCurrentDoc}
        completeDocArrayState={completeDocArrayState}
        stateBooleans={stateBooleans}
        scrollToTop={scrollToTop}
        storeFunctions={storeFunctions}
      />
    );

    page = (
      <DocumentPage
        classes={classes}
        documentImage={props.data.stateData.docImage}
        docupentImageReactReference={docImageRef}
        hasDocumentToDisplay={notAppuiDocs?.length || appuiDocs?.length}
        height={getSigningDocsHeight(notAppuiDocsSteps, docToSignRef)}
        documentIconsArray={documentIconsArray}
        notAppuiDocsSteps={notAppuiDocsSteps}
        setCurrentDoc={setCurrentDoc}
        stateBooleans={stateBooleans}
        scrollToTop={scrollToTop}
        stepperData={props.data}
        getStepperColor={getStepperColor}
        completeDocArrayState={completeDocArrayState}
        areAllDocumentsReadOrViewed={areAllDocumentsReadOrViewedState}
        displayActionComplete={displayActionComplete}
        storeFunctions={storeFunctions}
        isExpanded={isExpanded}
        downloadSectionElement={downloadSection}
        imageElement={image}
      />
    );

    appuipage = (
      <DocumentAppuiPage
        classes={classes}
        isExpanded={isExpanded}
        downloadSectionElement={downloadSection}
        imageElement={image}
      />
    );

    const currentStepId = getCurrentDocumentIndex(props.data.stateData);

    topButton = (
      <ToTopButton
        documentsToSignArray={completeDocArrayState}
        currentDocument={currentDoc}
        displayNextDocument={() => {
          const item = notAppuiDocsSteps[currentStepId + 1]?.item;
          displayActionComplete(
            item,
            setCurrentDoc,
            completeDocArrayState,
            stateBooleans,
            storeFunctions,
            scrollToTop
          );
        }}
      />
    )
  }

  return <><div>{isExpanded ? appuipage : page}</div>
    {topButton}
  </>;
}

export default withStyles(styles)(trackWindowScroll(DocumentsToSign));
