import React, { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Header from "../../Header";
import Footer from "../../Footer";
import "./AccessibilityReport.css";

const AccessibilityReport: FunctionComponent<{}> = () => {
  return (
    <div className={"accessibility-container"}>
      <Header />
      <Grid container>
        <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
        <Grid item xs={12} sm={10} lg={10} xl={8}>
          <div className={"regular-heading"}>
            <span className={"title"}>Mentions d’accessibilité</span>
          </div>
          <p>&nbsp;</p>
          <h4>DÉCLARATION D’ACCESSIBILITÉ</h4>
          <p>
            Société Générale s’engage à rendre ses sites internet, intranet,
            extranet et ses applications mobiles accessibles conformément à
            l’article 47 de la loi n° 2005-102 du 11 février 2005.
          </p>
          <p>
            À cette fin, il met en œuvre la stratégie et les actions suivantes :
          </p>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="Télécharger le schéma pluriannuel (nouvelle fenêtre)"
                href="https://www.societegenerale.com/sites/default/files/documents/2020-10/Sch%C3%A9ma%20Pluriannuel%20de%20Mise%20en%20Accessibilit%C3%A9.pdf"
              >
                schéma pluriannuel
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="Télécharger le plan d'action annuel (nouvelle fenêtre)"
                href="https://www.societegenerale.com/sites/default/files/documents/2020-12/Plan-dAction-Annuel-de-Mise-en-Accessibilite.pdf"
              >
                plan d’action annuel
              </a>
            </li>
          </ul>

          <p>Cette déclaration d’accessibilité s’applique à ISIGN.</p>
          <h4>ÉTAT DE CONFORMITÉ</h4>
          <p>
            L’application ISIGN est non-conforme avec le référentiel général
            d’amélioration de l’accessibilité (RGAA), version 4.1, en raison des
            non-conformités et des dérogations énumérées ci-dessous.
          </p>
          <h4>TESTS D’ACCESSIBILITÉ</h4>
          <p>
            L’audit de conformité réalisé par CFT/ITO Accessibility Team révèle
            que : &nbsp;
          </p>
          <ul>
            <li>
              67% des critères RGAA sont respectés. &nbsp;<br></br>Il s’agit du
              nombre de critères pleinement respectés sur la totalité des pages
              de l’échantillon.&nbsp;
            </li>
            <li>
              Le taux moyen de conformité du service en ligne s’élève à
              68%.&nbsp;<br></br>Il s’agit de la moyenne du score de conformité
              obtenu sur chacune des pages de l’échantillon.&nbsp;
            </li>
          </ul>
          <h4>CONTENUS NON ACCESSIBLES</h4>
          <ol>
            <li>
              <strong>Non-conformités</strong>&nbsp;
              <p>
                Nous listons ci-dessous l’ensemble des critères non-conformes.
                Pour le détail, se reporter à la grille d’audit.&nbsp;
              </p>
              <p>Critère 2.1: Chaque cadre a-t-il un titre de cadre  ?&nbsp;</p>
              <p>
                Critère 3.1: Dans chaque page web, l’information ne doit pas
                être donnée uniquement par la couleur. Cette règle est-elle
                respectée  ?&nbsp;
              </p>
              <p>
                Critère 3.2: Dans chaque page web, le contraste entre la couleur
                du texte et la couleur de son arrière-plan est-il suffisamment
                élevé (hors cas particuliers) ?&nbsp;
              </p>
              <p>
                Critère 7.1: Chaque script est-il, si nécessaire, compatible
                avec les technologies d’assistance ?
              </p>
              <p>
                Critère 8.6: Pour chaque page web ayant un titre de page, ce
                titre est-il pertinent  ?&nbsp;
              </p>
              <p>
                Critère 9.1: Dans chaque page web, l’information est-elle
                structurée par l’utilisation appropriée de titres ? &nbsp;
              </p>
              <p>
                Critère 9.2: Dans chaque page web, la structure du document
                est-elle cohérente (hors cas particuliers) ? &nbsp;
              </p>
              <p>
                Critère 10.4: Dans chaque page web, le texte reste-t-il lisible
                lorsque la taille des caractères est augmentée jusqu’à 200%, au
                moins (hors cas particuliers) ?&nbsp;
              </p>
              <p>
                Critère 11.10: Dans chaque formulaire, le contrôle de saisie
                est-il utilisé de manière pertinente (hors cas particuliers)
                 ?&nbsp;
              </p>
              <p>
                Critère 12.1: Chaque ensemble de pages dispose-t-il de deux
                systèmes de navigation différents, au moins (hors cas
                particuliers) ?&nbsp;
              </p>
              <p>
                Critère 12.6: Les zones de regroupement de contenus présentes
                dans plusieurs pages web (zones d’en-tête, de navigation
                principale, de contenu principal, de pied de page et de moteur
                de recherche) peuvent-elles être atteintes ou évitées ?&nbsp;
              </p>
              <p>
                Critère 12.7: Dans chaque page web, un lien d’évitement ou
                d’accès rapide à la zone de contenu principal est-il présent
                (hors cas particuliers) ? &nbsp;
              </p>
              <p>
                Critère 12.8: Dans chaque page web, l’ordre de tabulation est-il
                cohérent  ? &nbsp;
              </p>
            </li>
            <li>
              <p>
                <strong>Dérogations pour charge disproportionnée&nbsp;</strong>
                <br></br>Aucune dérogation pour charge disproportionnée n’a été
                faite.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Contenus non soumis à l’obligation d’accessibilité &nbsp;
                </strong>
                <br></br>L’article 3 du décret n°2019-768 du 24 juillet 2019
                établit une liste des contenus exemptés de l’obligation
                d’accessibilité. &nbsp;<br></br>Pas de contenu non soumis à
                l'obligation d'accessibilité
              </p>
            </li>
          </ol>
          <h4>ÉTABLISSEMENT DE CETTE DÉCLARATION D’ACCESSIBILITÉ</h4>
          <p>Cette déclaration a été établie le 3 juin 2022.&nbsp;</p>
          <h4>TECHNOLOGIES UTILISÉES POUR LA RÉALISATION DU SITE WEB</h4>
          <p>
            Les technologies suivantes sont utilisées sur le site web :&nbsp;
          </p>
          <ul>
            <li>HTML 5&nbsp;</li>
            <li>CSS&nbsp;</li>
            <li>JavaScript </li>
          </ul>
          <h4>
            AGENTS UTILISATEURS, TECHNOLOGIES D’ASSISTANCE ET OUTILS UTILISÉS
            POUR VÉRIFIER L’ACCESSIBILITÉ&nbsp;
          </h4>
          <p>
            Les tests des pages web ont été effectués avec les combinaisons de
            navigateurs web et lecteurs d’écran suivants :&nbsp;
          </p>
          <ul>
            <li>
              Agent utilisateur&nbsp;
              <ul>
                <li>
                  <p>Chrome 99.0.4844.84</p>
                </li>
                <li>
                  <p>NVDA 2021.3.5</p>
                </li>
              </ul>
            </li>
            <li>
              <p>Technologie d’assistance&nbsp;</p>
              <ul>
                <li>
                  <p>Chrome 92.0.4515.107&nbsp;</p>
                </li>
                <li>
                  <p>NVDA 2021.3.5 ILM&nbsp;</p>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <strong>Outils utilisés&nbsp;</strong>
          </p>
          <p>
            Les outils suivants ont été utilisés lors de l’évaluation :&nbsp;
          </p>
          <ul>
            <li>Inspecteur de code du navigateur (Chrome)&nbsp;</li>
            <li>
              Contrast-Finder pour tester les contrastes de couleurs.&nbsp;
            </li>
            <li>
              Extension HeadingsMap pour Chrome, pour visualiser la
              structuration par les titres.&nbsp;
            </li>
          </ul>
          <p>
            <strong>
              Pages du site ayant fait l’objet de la vérification de
              conformité&nbsp;
            </strong>
          </p>
          <ul>
            <li>
              Échantillon structuré&nbsp;
              <ul>
                <li>Sign Contract(s) / Manage Contract(s) &nbsp;</li>
                <li>Create Contract &nbsp;</li>
                <li>Add Signatorie(s) &nbsp;</li>
                <li>Agreement &nbsp;</li>
              </ul>
            </li>
          </ul>

          <h4>RETOUR D’INFORMATION ET CONTACT</h4>
          <p>
            Si vous n’arrivez pas à accéder à un contenu ou à un service, vous
            pouvez contacter le responsable de ISIGN pour être orienté vers une
            alternative accessible ou obtenir le contenu sous une autre forme.
          </p>
          <ul>
            <li>
              Envoyer un message à l’adresse "
              <a href="mailto:infos-isign@socgen.com">contacts</a>"
            </li>
            <li>
              <p>
                Contacter : <br></br>RESG/DDS/DCS<br></br>
                Tours Société Générale<br></br>
                189 rue d’Aubervilliers<br></br>
                75886 Paris cedex 18
              </p>
            </li>
          </ul>
          <h4>VOIES DE RECOURS</h4>
          <p>Cette procédure est à utiliser dans le cas suivant.</p>
          <p>
            Vous avez signalé au responsable du site intranet un défaut
            d’accessibilité qui vous empêche d’accéder à un contenu ou à un des
            services du portail et vous n’avez pas obtenu de réponse
            satisfaisante.
          </p>
          <ul>
            <li>
              Écrire un message au{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="Conctacter le Défenseur des droits (nouvelle fenêtre)"
                href="https://formulaire.defenseurdesdroits.fr/"
              >
                Défenseur des droits
              </a>
            </li>
            <li>
              Contacter le{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                title="Contacter le délégué du Défenseur des droits dans votre région (nouvelle fenêtre)"
                href="https://www.defenseurdesdroits.fr/saisir/delegues"
              >
                délégué du Défenseur des droits dans votre région
              </a>
            </li>
            <li>
              Envoyer un courrier par la poste (gratuit, ne pas mettre de
              timbre)<br></br>Défenseur des droits<br></br>Libre réponse 71120
              <br></br>75342 Paris CEDEX 07
            </li>
          </ul>
        </Grid>
        <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default AccessibilityReport;
