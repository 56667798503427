import React, { useEffect, useCallback } from "react";
import { useStoreState } from "easy-peasy";
import Header from "./Header";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { getCurrentSignatoryActivities } from "./services/consentUtils";
import FinalPage from "./components/FinalPage";
import MainStepper from "./MainStepper";
import Footer from "./Footer";
import "./App.css";
import History from "./History";
const actions = require("./actions/Stepper");

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(20),
  },
});

/**
 * Define if current display page need to be updated (html content need to be
 * changed with new step html consent value).
 * @param {*} caseData store object data
 */
const doesPageNeedUpdates = (caseData) => {
  return (
    (caseData.userAccessInfo.consent ||
      caseData.signatureUserAccessInfo.consent) &&
    caseData.stateData.activeStep === 0 &&
    caseData.stateData.activeSubStep === 0 &&
    caseData.stateData.needUpdate
  );
};

function Home(props) {
  const langState = useStoreState((state) => state.userInfo.lang);
  const caseData = props.data;

  useEffect(() => {
    actions.setLanguage(langState);
  }, [langState]);

  const isContractStateNotValid = useCallback(() => {
    return (
      caseData.stateData.tokenNotTranslated ||
      caseData.stateData.finished ||
      caseData.stateData.signAlreadyCompleted ||
      caseData.stateData.contractCancelled ||
      caseData.stateData.contractExpired ||
      caseData.stateData.contractInError ||
      caseData.stateData.loadingContractDataFailed
    );
  }, [
    caseData.stateData.tokenNotTranslated,
    caseData.stateData.finished,
    caseData.stateData.signAlreadyCompleted,
    caseData.stateData.contractCancelled,
    caseData.stateData.contractExpired,
    caseData.stateData.contractInError,
    caseData.stateData.loadingContractDataFailed,
  ]);

  useEffect(() => {
    if (
      !caseData.signatureUserAccessInfo.consent &&
      caseData.stateData.activeStep > 0 &&
      !isContractStateNotValid()
    ) {
      actions.getPendingActivity();
    }
  }, [
    caseData.signatureUserAccessInfo.consent,
    caseData.stateData.activeStep,
    isContractStateNotValid,
  ]);

  if (doesPageNeedUpdates(caseData)) {
    const data = () => {
      if (caseData.userAccessInfo.consent) {
        const currentSignatoryActivities = getCurrentSignatoryActivities(
          caseData.activity,
          caseData.stateData.signatory
        );
        const firstStep =
          currentSignatoryActivities?.length - 1 >= 0
            ? currentSignatoryActivities.length - 1
            : 0;
        actions.setCurrentActiveSubStep(firstStep);
        return caseData.userAccessInfo;
      } else {
        return caseData.signatureUserAccessInfo;
      }
    };
    actions.updateCurrentDisplayPage(data());
  }

  return (
    <div className="App">
      <Header
        setLanguage={(lang) => {
          const h = History;
          const urlHash = h.location.hash;
          const urlHashWithoutLang = urlHash.split("lang=");
          const urlHashWithLangUpdated = `${urlHashWithoutLang[0]}lang=${lang}`;

          History.push(urlHashWithLangUpdated);
        }}
        name={caseData.tokenResponse.signatoryName}
        email={caseData.tokenResponse.signatoryEmailId}
      />
      <div className={"main"} id="main">
        {isContractStateNotValid() ? (
          <FinalPage data={caseData} />
        ) : (
          <MainStepper data={caseData} />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default withStyles(styles)(withRouter(Home));
