import React from "react";
import { useStoreState } from "easy-peasy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";

const styles = () => ({
  iconButton: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    padding: "10px",
    marginLeft: "5px",
  },
  containerDiv: {
    display: "inline-block",
    verticalAlign: "middle",
    textAlign: "left",
  },
  button: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    fontSize: "15px",
  },
  expandLink: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    color: "#2469ff",
    fontSize: "12px",
  },
  expandLinkGreen: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    color: "#008b0a",
    fontSize: "12px",
  },
  signDownloadIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    marginBottom: "2px",
    color: "black",
  },
  signViewIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "auto",
    paddingBottom: "2px",
    marginLeft: "5px",
  },
});

/**
 * Display corresponding title on screen.
 * @param {boolean} isClickable check if document name is clickable
 * @param {UseTranslationResponse} t translation function
 */
const setDisplayedTitle = (isClickable, t) => {
  return (
    !isClickable && {
      view: t("viewActionRequiredLabel"),
      read: t("readActionRequiredLabel"),
    }
  );
};

/**
 * Return css style apply to the pointer depending on requierement.
 * @param {boolean} isClickable check if document name is clickable
 * @param {string} requirement current document requirement status
 * @param {string} style wanted style apply to the corresponding element
 */
const getPointerStyle = (isClickable, requirement, style) => {
  if (!isClickable && requirement) {
    switch (style) {
      case "not-allowed":
        return { cursor: "not-allowed" };
      case "none":
        return { pointerEvents: "none" };
      default:
        return undefined;
    }
  } else return undefined;
};

/**
 * Define if a document should be displayed or donwloaded once user click on its name.
 * @param {boolean} isDisplayable check if current document is displayable
 * @param {boolean} isClickable check if document name is clickable
 * @param {*} downloadAction document download action in store
 * @param {*} displayAction document display action in store
 */
const documentDisplayOrDownloadAction = (
  isDisplayable,
  isClickable,
  downloadAction,
  displayAction
) => {
  if (!isDisplayable) {
    return downloadAction;
  } else if (isClickable) {
    return displayAction;
  } else return undefined;
};

/**
 * Display correct document class depending on document status (displayable or not).
 * @param {boolean} isDisplayable check if current document is displayable
 * @param {*} classes list of available css classes
 */
const documentDisplayClass = (isDisplayable, classes) => {
  return isDisplayable ? `documentNameStyle ${classes.button}` : classes.button;
};

/**
 * Returns expand link css style depending on documents read/view global state.
 * @param {boolean} areAllDocumentsReadOrViewedState check if all document requirement are completed
 * @param {*} classes list of available css classes
 */
const getDocumentExpendLinkClass = (
  areAllDocumentsReadOrViewedState,
  classes
) => {
  return areAllDocumentsReadOrViewedState
    ? classes.expandLinkGreen
    : classes.expandLink;
};

function Document(props) {
  const { t } = useTranslation();
  const areAllDocumentsReadOrViewedState = useStoreState(
    (state) => state.documentsToSign.areAllDocumentsReadOrViewed
  );

  const {
    classes,
    isNotDownloadable,
    areDownloadFilesDisplayed,
    isExpanded,
    setIsExpanded,
    isClickable,
    requirement,
    isDisplayable,
  } = props;
  if (areDownloadFilesDisplayed) {
    areDownloadFilesDisplayed(isExpanded);
  }

  const downloadableElements = (
    <>
      {!isNotDownloadable ? (
        <div
          id={props.key}
          style={getPointerStyle(isClickable, requirement, "none")}
        >
          <div
            className={documentDisplayClass(isDisplayable, classes)}
            onClick={documentDisplayOrDownloadAction(
              isDisplayable,
              isClickable,
              props.downloadAction,
              props.displayAction
            )}
          >
            {props.name}
          </div>
          <>
            {!isDisplayable && (
              <CustomButton
                icon="SaveAltIcon"
                onClick={props.downloadAction}
                classes={classes}
                isClickable={!isClickable}
                name={props.name}
              />
            )}
          </>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  const notDownloadableNotExpandedElements = (
    <>
      {!isExpanded && isNotDownloadable ? (
        <>
          <div
            onClick={() => setIsExpanded(true)}
            className={getDocumentExpendLinkClass(
              areAllDocumentsReadOrViewedState,
              classes
            )}
          >
            {props.name}
          </div>
          <ExpandMoreIcon
            className={classes.signDownloadIcon}
            onClick={() => setIsExpanded(true)}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );

  const notDownloadableExpandedElements = (
    <>
      {isExpanded && isNotDownloadable ? (
        <>
          <div
            onClick={() => setIsExpanded(false)}
            className={getDocumentExpendLinkClass(
              areAllDocumentsReadOrViewedState,
              classes
            )}
          >
            {props.name}
          </div>
          <ExpandLessIcon
            className={classes.signDownloadIcon}
            onClick={() => setIsExpanded(false)}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <div
      className={classes.containerDiv}
      style={getPointerStyle(isClickable, requirement, "not-allowed")}
      title={setDisplayedTitle(isClickable, t)[`${requirement}`]}
    >
      {downloadableElements}
      {notDownloadableNotExpandedElements}
      {notDownloadableExpandedElements}
    </div>
  );
}
export default withStyles(styles)(Document);
