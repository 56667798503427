import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import renderHTML from "react-render-html";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import {
  getSortedClausesAsTextBlock,
  getClauseByLang,
} from "../services/consentUtils";
const actions = require("../actions/Stepper");

const styles = (theme) => ({
  content: {
    /*marginTop: theme.spacing(2),*/
    margin: theme.spacing(2),
    alignItems: "justify",
    marginBottom: theme.spacing(2),
    textAlign: "left",
    fontSize: "14px",
  },
});

function InformationStep(props) {
  const langState = useStoreState((state) => state.userInfo.lang);
  const [storeData, setStoreData] = useState(props);

  useEffect(() => {
    var checkboxes = document.getElementsByClassName("form-checkbox");
    var text = document.getElementsByTagName("label");

    setStoreData(props);
    if (checkboxes && checkboxes[0] && text && text[0]) {
      checkboxes[0].onclick = function () {
        if (checkboxes[0].checked) {
          actions.setConsentProvidedText(text[0].innerText);
          actions.setNextButtonActiveState(true);
          actions.setCheckboxTickedState(true);
        } else {
          actions.setNextButtonActiveState(false);
          actions.setCheckboxTickedState(false);
        }
      };
    }
  }, [props]);

  const { classes } = storeData;
  const clauses = storeData.data.userAccessInfo.consent.clauses;
  const sortedClausesAsText = getSortedClausesAsTextBlock(clauses);
  const filteredSortedClausesAsText = getClauseByLang(
    sortedClausesAsText,
    langState,
    storeData.data.userAccessInfo.metadata
  );
  const sortedClausesAsHTML = renderHTML(filteredSortedClausesAsText);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.content}>
        <div>{sortedClausesAsHTML}</div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(InformationStep);
