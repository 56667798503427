import React from "react";
import { useStoreState } from "easy-peasy";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Spinner } from "reactstrap";

const actions = require("../actions/Stepper");

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    alignItems: "center",
  },
});

/**
 * Define if next button is available or not.
 * @param {*} props contract data stored in state
 */
const isContinueButtonEnable = (props) => {
  if (props.data.stateData.currentActiveStep.isForm) {
    return (
      props.data.stateData.isCheckboxTicked &&
      props.data.stateData.isNextButtonActivated
    );
  }
  if (props.data.stateData.currentActiveStep.isRecap) {
    return (
      props.data.stateData.docConsentProvided.length ===
      props.data.stateData.totalDocToSign
    );
  }
  if (props.data.stateData.currentActiveStep.isConvPreuv) {
    return props.data.stateData.isNextButtonActivated;
  }
  if (props.data.stateData.currentActiveStep.isForm) {
    return (
      props.data.stateData.isCheckboxTicked &&
      props.data.stateData.isNextButtonActivated
    );
  }
  if (props.data.stateData.currentActiveStep.isOTP) {
    return props.data.stateData.signReadyToComplete;
  }
};

/**
 * Define if next button is loading or not.
 * @param {*} props contract data stored in state
 */
let isButtonLoading = (props) => {
  return props.data.stateData.isNextButtonLoading ? true : false;
};

/**
 * Define if previous button is disabled or not.
 * @param {*} props contract data stored in state
 */
let isPreviousButtonDisable = (props) => {
  if (props.data.stateData.activeStep <= 1) {
    return true;
  } else {
    if (props.data.stateData.activeSubStep > 0) {
      return false;
    } else if (
      props.data.stateData.stepToLoadDataFrom < props.data.stateData.activeStep
    ) {
      return false;
    } else {
      return true;
    }
  }
};

/**
 * Define the css style of the next button
 * @param {number} activeStep current active step in signing application
 * @param {boolean} isDisabled define if next button is avaliable or not
 */
const buttonBorderColor = (activeStep, isDisabled) => {
  if (activeStep === 3 && !isDisabled) {
    return "#00AA0C";
  } else {
    return "black";
  }
};

/**
 * Disable next button if enable conditions are not met.
 * @param {*} props contract data stored in state
 * @param {boolean} areAllDocumentsReadOrViewedState define if all requirement are completed
 * @param {boolean} onlyAppuiDocumentsState define if all documents are present in state
 * @param {boolean} isContinueEnabled define if next button is available
 */
const isButtonDisabled = (
  props,
  areAllDocumentsReadOrViewedState,
  onlyAppuiDocumentsState,
  isContinueEnabled
) => {
  if (props.data.stateData.currentActiveStep.isDocumentsToSign) {
    return !areAllDocumentsReadOrViewedState && !onlyAppuiDocumentsState;
  } else {
    return !isContinueEnabled;
  }
};

/**
 * Return css class to apply on next button.
 * @param {boolean} isOTP define if current step is OTP step
 * @param {boolean} isDisabled define if next button is disabled
 */
const getNextButtonClass = (isOTP, isDisabled) => {
  if (!isDisabled) {
    return isOTP ? "btn-success" : "btn-primary";
  } else {
    return "btn-flat-primary";
  }
};

function StepperNavigator(props) {
  const { t } = useTranslation();
  const areAllDocumentsReadOrViewedState = useStoreState(
    (state) => state.documentsToSign.areAllDocumentsReadOrViewed
  );
  const onlyAppuiDocumentsState = useStoreState(
    (state) => state.documentsToSign.onlyAppuiDocuments
  );
  const { classes } = props;
  const activeStep = props.data.stateData.activeStep;
  const isContinueEnabled = isContinueButtonEnable(props);
  const isDisabled = isButtonDisabled(
    props,
    areAllDocumentsReadOrViewedState,
    onlyAppuiDocumentsState,
    isContinueEnabled
  );
  const borderColor = buttonBorderColor(activeStep, isDisabled);
  const isLoading = isButtonLoading(props);
  const isPreviousDisabled = isPreviousButtonDisable(props);
  const nextButtonClass = getNextButtonClass(
    props.data.stateData.currentActiveStep.isOTP,
    isDisabled
  );

  const goesToBody = () => {
    const container = document.getElementById("main");
    if (container) {
      container.tabIndex = 0;
      container.focus();
      setTimeout(() => container.removeAttribute("tabindex"), 1000);
    }
  };

  const nextButton = document.getElementById("nextButton");
  const backButton = document.getElementById("backButton");
  if (nextButton) {
    nextButton.addEventListener("click", goesToBody);
  }
  if (backButton) {
    backButton.addEventListener("click", goesToBody);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
        <Grid item xs={12} sm={10} lg={10} xl={8}>
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <button
                id="nextButton"
                className={"btn " + nextButtonClass}
                disabled={isDisabled}
                style={{ borderColor: borderColor }}
                onClick={actions.nextActivity}
              >
                {isLoading && (
                  <div
                    className="container-centered"
                    style={{ display: "inline-block" }}
                  >
                    <Spinner
                      color="dark"
                      style={{
                        width: "0.7rem",
                        height: "0.7rem",
                        display: "inline-block",
                        margin: "2px",
                      }}
                    />
                  </div>
                )}
                {props.data.stateData.currentActiveStep.isOTP
                  ? t("finish")
                  : t("continue")}
                <ChevronRightIcon />
              </button>
            }
            backButton={
              <button
                id="backButton"
                className=" btn btn-flat-primary"
                disabled={isPreviousDisabled}
                style={{ borderColor: "black" }}
                onClick={actions.previous}
              >
                <ChevronLeftIcon />
                {t("back")}
              </button>
            }
          />
        </Grid>
        <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
      </Grid>
    </>
  );
}

export default withStyles(styles)(StepperNavigator);
