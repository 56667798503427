import React from "react";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

function CustomButton(props) {
  const {
    classes,
    onClick,
    index,
    lastIndex,
    isClickable,
    requirement,
    iconColor,
  } = props;

  /**
   * Set download icon style setting same position as documents name in
   * the stepper.
   */
  const setSaveAltIconStyle = () => {
    if (index === lastIndex) {
      return !isClickable && requirement
        ? {
            cursor: "not-allowed",
            display: "block",
            alignSelf: "flex-end",
          }
        : {
            display: "block",
            alignSelf: "flex-end",
          };
    } else {
      return !isClickable && requirement
        ? { cursor: "not-allowed" }
        : undefined;
    }
  };

  /**
   * Set what type of icon you want to use as a button (pass it as a parameter)
   * @param {string} icon desired icon to display
   */
  function setIcon(icon) {
    if (icon === "SaveAltIcon")
      return <SaveAltIcon className={classes.signDownloadIcon} />;
    if (icon === "SaveAltIconToggle")
      return (
        <SaveAltIcon
          className={classes.signDownloadIcon}
          htmlColor={iconColor}
          style={setSaveAltIconStyle()}
        />
      );
  }

  return (
    <button className="saveAltButton" onClick={onClick} disabled={!isClickable}>
      {setIcon(props.icon)}
    </button>
  );
}

export default CustomButton;
