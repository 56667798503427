import React, { useRef } from "react";
import ChevronUpIcon from "@material-ui/icons/ExpandLessRounded";


/**
 * Define if the current document has been read and if it is not the last in the list.
 * @param {*} fullDocumentsArray list of all documents to sign in current contract
 * @param {*} currentDoc current opened document in the viewer
 */
const isToTopButtonDisplayed = (fullDocumentsArray, currentDoc) => {
    let isDisplayed = false;
    const noOfDocs = fullDocumentsArray.length;
    const lastObject = fullDocumentsArray[noOfDocs - 1];
    fullDocumentsArray.forEach((document) => {
        if (document.name === currentDoc.name) {
            let isLastDoc = lastObject.name === currentDoc.name;
            isDisplayed = document.hasBeenOpened && document.hasBeenRead && !isLastDoc;
        }
    })
    return isDisplayed;
};

/**
 * Displays scroll to top button if user is not at the top of the screen.
 * @param {HTMLElement | null} topButton go up button element
 */
const displayScrollToTopButton = (topButton) => {
    const buttonDisplayedMaxHeight = window.innerHeight * 0.3;
    if (topButton) {
        if (
            document.body.scrollTop > buttonDisplayedMaxHeight ||
            document.documentElement.scrollTop > buttonDisplayedMaxHeight
        ) {
            topButton.style.display = "block";
        } else {
            topButton.style.display = "none";
        }
    }
};

/**
* Actions to go bact to top of the screen.
*/
const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

function ToTopButton(props) {

    const {
        documentsToSignArray,
        currentDocument,
        displayNextDocument,
    } = props

    window.onscroll = function () {
        displayScrollToTopButton(topButtonRef?.current);
    };

    const topButtonRef = useRef(null);

    /**
     * On top button click, user is redirected to top of the page and displayed document
     * is updated (next one is the document to sign list is displayed in the viewer).
     */
    const goToNextDocument = () => {
        goToTop();
        displayNextDocument();

    }

    return (
        <div className={"nav-button-container"}>
            {isToTopButtonDisplayed(documentsToSignArray, currentDocument) &&
                <button id="toTop" onClick={goToNextDocument} ref={topButtonRef}>
                    <ChevronUpIcon />
                </button>
            }
        </div>
    )
}

export default ToTopButton
