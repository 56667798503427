var Reflux = require("reflux");

var Stepper = Reflux.createActions([
  "getInitialState",
  "setIPAddress",
  "getPendingActivity",
  "nextActivity",
  "updateCurrentDisplayPage",
  "setConsentProvidedText",
  "createChannel",
  "next",
  "previous",
  "clear",
  "loadDocumentsToSign",
  "downloadDocument",
  "docConsentProvided",
  "tcuConsentProvided",
  "otpConsentProvided",
  "generateCertificate",
  "resetOTPCounter",
  "sendOTP",
  "validateOTP",
  "displayDocument",
  "displayAppuiDocMessage",
  "downloadSignedFilesZip",
  "setNextButtonActiveState",
  "setCheckboxTickedState",
  "setCurrentActiveSubStep",
  "setLanguage",
]);

module.exports = Stepper;
