import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

function LoadingPage(props) {
  const { t } = useTranslation();
  const [message, setMessage] = useState();
  const { isDocument, isDocumentList } = props;

  useEffect(() => {
    if (props.data.stateData.isUpdatingStepStatus) {
      setMessage(t("updatingStepStatus"));
    } else if (isDocument) {
      setMessage(t("DocumentLoadingMessage"));
    } else if (isDocumentList) {
      setMessage(t("DocumentListLoadingMessage"));
    } else {
      setMessage(t("loadingMessage"));
    }
  }, [props, t, isDocument, isDocumentList]);

  return (
    <div
      className="App"
      style={{ padding: "15px" }}
      aria-label={`${t("loadingPageArialLabel")}`}
    >
      <Grid>
        <CircularProgress disableShrink />
        <Typography variant={"h6"}>{message}</Typography>
      </Grid>
    </div>
  );
}
export default LoadingPage;
