import fetchIntercept from "fetch-intercept";
import History from "./History";

fetchIntercept.register({
  response: function (response) {
    switch (window.env.DOWN_PAGE_CONTROL) {
      case "redirect-all":
        History.push("/internal-error");
        break;
      case "redirect-none":
        return response;
      default:
        if (response.status >= 500 && response.status <= 599) 
          History.push({pathname: "/internal-error", errorcode:response.status});
        else return response;
    }
  },
});
