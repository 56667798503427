import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import Grid from "@material-ui/core/Grid";
import renderHTML from "react-render-html";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import {
  getConvPreuvClauses,
  getSortedClausesAsText,
  getConsentValue,
  getClauseByLang,
  getSortedDocs,
} from "../services/consentUtils";

var actions = require("../actions/Stepper");
const styles = (theme) => ({
  content: {
    /*marginTop: theme.spacing(2),*/
    margin: theme.spacing(2),
    alignItems: "center",
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  checkboxLine: {
    marginLeft: theme.spacing(6),
  },
});

/**
 * Get recap checkbox html element.
 * @param {HTMLCollectionOf<Element>} formCheckboxes recap checkbox html element
 */
const getRecapCheckbox = (formCheckboxes) => {
  return formCheckboxes && formCheckboxes[0];
};

/**
 * Define if next button is available.
 * @param {HTMLCollectionOf<Element>} formCheckboxes recap checkbox html element
 */
const setNextButtonState = (formCheckboxes) => {
  if (getRecapCheckbox(formCheckboxes)) {
    if (!getRecapCheckbox(formCheckboxes).checked) {
      actions.setNextButtonActiveState(false);
    }
  }
};

/**
 * Manage 2nd recap checkbox state. If user tick the box, goes to next page and comes back to the recap,
 * the box is disabled as consent has been given already. Also, NEXT button is avalaible only if the box
 * is ticked.
 * @param {string} consentProvidedTextOnCurrentStep current page consent text (if provided)
 * @param {boolean} consentProvidedOnCurrentStep check if consent has been provided on current step
 * @param {boolean} isUpdated check if recap checkbox has been updated
 * @param {HTMLCollectionOf<Element>} formCheckboxes recap checkbox html element
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsUpdated update recap checkbox state
 */
const setRecapBoxChecked = (
  consentProvidedTextOnCurrentStep,
  consentProvidedOnCurrentStep,
  isUpdated,
  formCheckboxes,
  setIsUpdated
) => {
  // Check if consent have been provided for current step.
  if (
    consentProvidedTextOnCurrentStep !== undefined &&
    consentProvidedOnCurrentStep &&
    !isUpdated
  ) {
    // Disable the checkbox as consent has been given already.
    getRecapCheckbox(formCheckboxes).disabled = true;
    getRecapCheckbox(formCheckboxes).checked = true;
    actions.setNextButtonActiveState(true);
    setIsUpdated(true);
  }
};

/**
 * Update recap checbox behaviour on actions performs when user interacts with the recap checkbox.
 * @param {HTMLCollectionOf<Element>} formCheckboxes recap checkbox html element
 * @param {HTMLCollectionOf<HTMLLabelElement>} text recap checkbox consent html text
 */
const updateRecapCheckboxState = (formCheckboxes, text) => {
  getRecapCheckbox(formCheckboxes).onclick = function () {
    if (getRecapCheckbox(formCheckboxes).checked) {
      actions.setNextButtonActiveState(true);
      actions.setConsentProvidedText(text[0]?.innerText);
    } else {
      actions.setNextButtonActiveState(false);
    }
  };
};

function ContractualClause(props) {
  const langState = useStoreState((state) => state.userInfo.lang);
  const { t } = useTranslation();
  const [storeData, setStoreData] = useState(props);
  const [isUpdated, setIsUpdated] = useState(false);

  const currentStep = storeData?.data?.stateData?.activeStep;
  const formCheckboxes = document.getElementsByClassName("form-checkbox");
  const text = document.getElementsByTagName("label");

  const consentProvidedTextOnCurrentStep =
    storeData.data.stateData.consentProvidedText.get(currentStep);
  const consentProvidedOnCurrentStep =
    storeData.data.stateData.consentProvided.get(currentStep);

  useEffect(() => {
    const channelPayload = { viewId: "dictao-dtp-bpm_4.2.8.0", timeout: 3600 };
    actions.createChannel(channelPayload);
    setNextButtonState(formCheckboxes);
  }, [formCheckboxes]);

  useEffect(() => {
    setStoreData(props);
    if (getRecapCheckbox(formCheckboxes)) {
      setRecapBoxChecked(
        consentProvidedTextOnCurrentStep,
        consentProvidedOnCurrentStep,
        isUpdated,
        formCheckboxes,
        setIsUpdated
      );
      updateRecapCheckboxState(formCheckboxes, text);
    }
  }, [
    props,
    currentStep,
    isUpdated,
    formCheckboxes,
    consentProvidedTextOnCurrentStep,
    consentProvidedOnCurrentStep,
    storeData.data.stateData.consentProvided,
    storeData.data.stateData.consentProvidedText,
    text,
  ]);

  const { classes } = storeData;
  const clauses = storeData.data.signatureUserAccessInfo.consent.clauses;
  const metadata = storeData.data.signatureUserAccessInfo.metadata;
  const signingDoc = getSortedDocs(storeData.data.stateData.signingDoc);
  const sortedClausesAsText = getSortedClausesAsText(clauses, 200);
  const sortedClausesAsTextBlock = getConvPreuvClauses(
    clauses.filter((clause) => !clause.document_type_ref.includes("Recap"))
  );

  const filteredSortedClausesAsTextPart1 = getClauseByLang(
    sortedClausesAsText.part1,
    langState,
    metadata
  );
  const filteredSortedClausesAsTextPart2 = getClauseByLang(
    sortedClausesAsText.part2,
    langState,
    metadata
  );
  const filteredSortedClausesAsTextBlock = getClauseByLang(
    sortedClausesAsTextBlock,
    langState,
    metadata
  );
  const sortedClausesAsHTMLPart1 = renderHTML(filteredSortedClausesAsTextPart1);
  const sortedClausesAsHTMLPart2 = renderHTML(filteredSortedClausesAsTextPart2);
  const sortedClausesAsHTML = renderHTML(filteredSortedClausesAsTextBlock);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.content}>
        {storeData.data.stateData.currentActiveStep.isRecap ? (
          <>
            {isUpdated && setIsUpdated(false)}
            <div>{sortedClausesAsHTMLPart1}</div>
            {signingDoc.map((item, index) => (
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={
                          storeData.data.stateData.docConsentProvided.indexOf(
                            item.name
                          ) !== -1
                        }
                        onChange={actions.docConsentProvided.bind(
                          null,
                          getConsentValue(item)
                            ? getConsentValue(item)
                            : t("docConsentProvidedText"),
                          item.name
                        )}
                        value="consentProvided"
                        inputProps={{
                          ariaLabel: "{item.name}",
                        }}
                      />
                    }
                    label={
                      <div>
                        <b>{item.name}</b>
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            ))}
            <div>{sortedClausesAsHTMLPart2} </div>
          </>
        ) : (
          <div>{sortedClausesAsHTML}</div>
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ContractualClause);
