import React from "react";
import { Router } from "react-router";
import { createBrowserHistory } from "history";

const appHistory = createBrowserHistory();

const RouterProvider = ({ children }) => {
  return <Router history={appHistory}>{children}</Router>;
};

export default RouterProvider;
