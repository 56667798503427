import React, { Suspense } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Providers from "./providers";
import { ToastContainer } from "react-toastify";
import "./InterceptFetchApi.js";

import Home from "./Home";
import Legal from "./Legal";
import TermsOfUse from "./TermsOfUse";
import DataProtectionPolicy from "./DataProtectionPolicy";
import Login from "./Login";
import Download from "./Download";
import Loader from "./components/Loader";
import ErrorPage from "./ErrorPage";
import AccessibilityReport from "./components/Accessibility/AccessibilityReport";

import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Providers>
          <Switch>
            <Route
              exact
              path={process.env.REACT_APP_BASE_NAME + "/legal"}
              component={Legal}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_NAME + "/terms-of-use"}
              component={TermsOfUse}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_NAME + "/data-protection-policy"}
              component={DataProtectionPolicy}
            />
            <Route
              path={process.env.REACT_APP_BASE_NAME + "/isign/signataire/"}
              component={Login}
            />
            <Route
              path={process.env.REACT_APP_BASE_NAME + "/isign/download/"}
              component={Download}
            />
            <Route
              exact
              path={process.env.REACT_APP_BASE_NAME + "/accessibility"}
              component={AccessibilityReport}
            />
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/internal-error"
              render={() => <ErrorPage error="500" />}
            />
            <Route exact render={() => <ErrorPage error="404" />} />
          </Switch>
        </Providers>
      </Suspense>
      <ToastContainer />
    </>
  );
}

export default App;
