import React from "react";
import Home from "./Home";
import store from "./stores/Stepper";
import LoadingPage from "./components/LoadingPage";

var Reflux = require("reflux");
var actions = require("./actions/Stepper");

export default class Login extends Reflux.Component {
  constructor() {
    super();
    this.stores = [store];
    this.storeKeys = [
      "accessId",
      "userAccessInfo",
      "tokenResponse",
      "activity",
      "channel",
      "stateData",
      "signatureUserAccessInfo",
      "signatureAccessId",
      "signatureActivity",
      "errors",
      "docConsentProvided",
      "success",
    ];
    this.state = {};
  }

  async componentDidMount() {
    actions.setIPAddress();
    let params = window.location.hash.replace("#/?", "").split("&");
    let token = params[0].replace("login=", "");
    let payload = { token: token };
    actions.getInitialState(payload);
  }

  render() {
    return (
      <div>
        {this.state.stateData.loaded ? (
          <Home data={this.state} />
        ) : (
          <LoadingPage data={this.state} />
        )}
      </div>
    );
  }
}
