var endpoint = {
  //ISIGN internal APIS
  tokenTranslation: "/isign/signing-service/api/v1/token",
  sendOTP: "/isign/signing-service/api/v2/otp",
  validateOTP:
    "/isign/signing-service/api/v2/otp/CODE_ID/OTP/SIGNATORY_LOGIN/validate",
  proofOTP: "/isign/signing-service/api/v2/otp/SIGNATORY_LOGIN/proof",
  //page1 API
  getChannelResponse: "/isign/signing-service/api/v1/channel/",
  saveConsent: "/isign/signing-service/api/v1/consent/PATH_PARAM",
  acceptOngoingTask: "/isign/signing-service/api/v1/activity/accept/TASK_ID",
  //page2API
  getPendingSignatureActivity:
    "/isign/signing-service/api/v1/activity/?case_ref=CASE_REF&type=TYPE", //Case _ref: 51fd3ee3-1671-4ca9-b0eb-03c440a155de status:active type: signature_session
  userSpaceAuthentication:
    "/isign/signing-service/api/v1/userspace/authentication/SIGNATORY_REF?contextUrl=CONTEXT_URL", //post
  //userSpaceAccess:
  //"/isign/signing-service/api/v1/userspace/?contextUrl=CONTEXT_URL", //context url api called //get
  downloadDocument:
    "/isign/signing-service/api/v1/document/?indexUrl=INDEX_URL", //get
  getDocumentIndexUrlInfo:
    "/isign/signing-service/api/v1/document/index?indexUrl=INDEX_URL",
  getDocumentImage: "/isign/signing-service/api/v1/document/image?url=PNG_URL",
  //page3API check box page
  createChannel: "/isign/signing-service/api/v1/channel/ACCESS_ID", //getChannelResponse //post
  saveConsent2: "/isign/signing-service/api/v1/consent/PATH_PARAM", //saveConsent

  //Page4API otp page
  generateCertificate: "/isign/signing-service/api/v1/certificate/ACCESS_ID",
  //    "sendOTP":"/isign/signing-service/api/v1/otp/send/ACCESS_ID",
  //   "validateOTP":"/isign/signing-service/api/v1/otp/validate/ACCESS_ID",
  acceptCertificate: "/isign/signing-service/api/v1/certificate/ACCESS_ID",
  completeSignTask: "/isign/signing-service/api/v1/activity/sign/TASK_ID",
  //DownloadSignedFilesZip
  downloadSignedFileZip:
    "/isign/signing-service/api/v1/token/downloadZip?idRef=DOC_TOKEN", //get
};

export default function APIUtils(props) {
  return process.env.REACT_APP_SIGNING_API_URI + endpoint[props];
}
