import React, { useEffect, useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";

const styles = () => ({
  iconButton: {
    width: "0.9em",
    height: "0.9em",
    paddingBottom: "5px",
  },
  signDownloadIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    paddingBottom: "4px",
    width: "0.9em",
    height: "0.9em",
  },
  signViewIcon: {
    center: "vertical",
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "auto",
    paddingBottom: "3px",
    marginLeft: "4px",
    width: "0.9em",
    height: "0.9em",
  },
});

function DocumentIcons(props) {
  const { t } = useTranslation();
  const stepperSteps = document.getElementsByClassName("sgbs-stepper-step");
  const [divHeight, setDivHeight] = useState(0);

  const {
    classes,
    status,
    index,
    isClickable,
    requirement,
    lastIndex,
    isSelected,
    areAllDocumentsReadOrViewed,
    name,
  } = props;

  const setDisplayedTitle = () => {
    if (!isClickable && requirement === "read") {
      return t("readActionRequiredLabel");
    } else if (!isClickable && requirement === "view") {
      return t("viewActionRequiredLabel");
    }
  };

  /**
   * Set same icon color as the one used by the stepper:
   * - #008b0a (green): all documents are read or viewed
   * - #2469ff (blue): requirement completed or in progress
   * - #6e777a (grey): document not available
   */
  const setIconColor = () => {
    if (areAllDocumentsReadOrViewed) {
      return "#008b0a";
    } else {
      if (status === "completed" || status === "in progress") {
        return "#2469ff";
      } else {
        return "#6e777a";
      }
    }
  };

  /**
   * Set icon container div style. Heigth depend on the corresponding
   * stepper clientHeight.
   */
  const setContainerDivStyle = () => {
    if (index === 0 || index === lastIndex) {
      return {
        display: "flex",
        height: divHeight,
      };
    } else {
      return {
        display: "flex",
        height: divHeight,
        lineHeight: divHeight + "px",
      };
    }
  };

  /**
   * Set icon style setting same position as documents name in
   * the stepper.
   */
  const setIconStyle = () => {
    if (index === lastIndex) {
      return { display: "inline-flex", alignSelf: "end" };
    } else {
      if (index === 0) {
        return {};
      } else {
        return { display: "inline-flex", alignSelf: "center" };
      }
    }
  };

  useEffect(() => {
    stepperSteps &&
      stepperSteps.length > 0 &&
      setDivHeight(stepperSteps[index].clientHeight);
  }, [stepperSteps, divHeight, index]);

  const displayIcons = (
    <>
      {divHeight > 0 && (
        <div style={{ display: "flex" }}>
          <div style={setContainerDivStyle()}>
            <div style={setIconStyle()} title={setDisplayedTitle()}>
              <CustomButton
                icon="SaveAltIconToggle"
                iconColor={setIconColor()}
                onClick={props.downloadAction}
                classes={classes}
                status={status}
                areAllDocumentsReadOrViewed={areAllDocumentsReadOrViewed}
                index={index}
                lastIndex={lastIndex}
                isClickable={isClickable}
                requirement={requirement}
                name={name}
              />
            </div>

            {isSelected && (
              <div style={setIconStyle()}>
                <VisibilityIcon
                  htmlColor={setIconColor()}
                  className={classes.signViewIcon}
                  titleAccess={t("viewIconTitle")}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );

  return <>{displayIcons}</>;
}
export default withStyles(styles)(DocumentIcons);
