import React, { useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MobileStepper from "@material-ui/core/MobileStepper";
import Header from "./Header";
import Footer from "./Footer";
import { objectTCU } from "./services/consentUtils";
import "./App.css";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  h1Style: {
    margin: "24px 20px 24px 20px",
    textAlign: "left",
  },
  h2Style: {
    margin: "24px 0px 24px 0px",
    textAlign: "left",
  },
  tabComponent: {
    backgroundColor: "#f8f9fa",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  content: {
    margin: theme.spacing(2),
    alignItems: "justify",
    marginBottom: theme.spacing(2),
    textAlign: "justify",
    fontSize: "14px",
  },
});

/**
 * Display all TCU lines on the screen.
 * @param {*} t translation response
 * @param {*} classes css classes
 */
const getTCULineToDisplay = (t, classes) => {
  return (
    <>
      {objectTCU(t).map((item, index) => {
        const subTitleIndexes = [
          0, 4, 10, 14, 17, 18, 21, 24, 27, 30, 34, 35, 37, 44, 47, 54, 57,
        ];
        if (subTitleIndexes.includes(index)) {
          return <h2 className={classes.h2Style}>{item.line}</h2>;
        } else {
          return <div>{item.line}</div>;
        }
      })}
    </>
  );
};

function TermsOfUse(props) {
  const parameters = props.history.location.search;
  const lang =
    parameters.split("?lang=").length > 1 &&
    parameters.split("?lang=")[1].split("&")[0];
  const userNameArray =
    parameters.split("&userName=").length > 1 &&
    parameters.split("&userName=")[1].split("&userEmail")[0].split("%20");
  const userName = ` ${userNameArray[1]} ${userNameArray[2]}`;
  const userEmail =
    parameters.split("userEmail=").length > 1 &&
    parameters.split("userEmail=")[1];

  const { classes } = props;
  const { t } = useTranslation();
  const setLanguageAction = useStoreActions(
    (state) => state.userInfo.setLanguage
  );
  const setUserAction = useStoreActions((state) => state.userInfo.setUser);

  useEffect(() => {
    setLanguageAction(lang);
    setUserAction({ name: userName, mail: userEmail });
  }, [setLanguageAction, setUserAction, userName, userEmail, lang]);

  function handleClick() {
    props.history.back();
  }

  return (
    <div className="App">
      <Header
        setLanguage={() => {
          const history = props.history;
          const urlHash = history.location.search;
          const urlHashWithoutLang = urlHash.split("?lang=");
          const urlHashWithLangUpdated = `?lang=${lang}&userName${
            urlHashWithoutLang[1].split("&userName")[1]
          }`;
          history.push(urlHashWithLangUpdated);
        }}
        name={userName}
        email={userEmail}
      />
      <div className={"main"}>
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
            <Grid item xs={12} sm={10} lg={10} xl={8}>
              <h1 className={classes.h1Style}>{t("TCU.title")}</h1>
              <div className={classes.tabComponent}>
                <Grid container>
                  <Grid item xs={12} className={classes.content}>
                    {getTCULineToDisplay(t, classes)}
                  </Grid>
                </Grid>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <MobileStepper
                    variant="dots"
                    steps={0}
                    position="static"
                    activeStep={null}
                    className={classes.root}
                    nextButton={null}
                    backButton={
                      <button
                        className=" btn btn-primary"
                        onClick={handleClick}
                      >
                        <ChevronLeftIcon />
                        {t("back")}
                      </button>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withStyles(styles)(TermsOfUse);
