import { action } from "easy-peasy";
import i18n from "i18next";

const userInfo = {
  lang: "en",
  setLanguage: action((state, payload) => {
    state.lang = payload;
    i18n.changeLanguage(state.lang, function (e, t) {
      if (e) throw e;
    });
  }),
  user: {
    name: "Name",
    mail: "Email",
  },
  setUser: action((state, payload) => {
    state.user = payload;
  }),
};

export default userInfo;
