import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Stepper from "../Stepper";

function DocumentPage(props) {
  const {
    classes,
    documentImage,
    docupentImageReactReference,
    hasDocumentToDisplay,
    height,
    documentIconsArray,
    notAppuiDocsSteps,
    setCurrentDoc,
    stateBooleans,
    scrollToTop,
    stepperData,
    getStepperColor,
    completeDocArrayState,
    areAllDocumentsReadOrViewed,
    displayActionComplete,
    storeFunctions,
    isExpanded,
    downloadSectionElement,
    imageElement,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid container>
      {documentImage.length > 0 ? (
        <div className={classes.downloadDocumentMessage}>
          {t("DocWithPreviewMsg")}
        </div>
      ) : (
        ""
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        className={classes.leftColumnWhite}
      >
        {hasDocumentToDisplay ? (
          <>
            <Stepper
              signingDocsHeight={height}
              documentIconsArray={documentIconsArray}
              notAppuiDocsSteps={notAppuiDocsSteps}
              color={getStepperColor(areAllDocumentsReadOrViewed)}
              docImageRef={docupentImageReactReference}
              onClick={(event, stepId) => {
                const item = notAppuiDocsSteps[stepId - 1]?.item;
                displayActionComplete(
                  item,
                  setCurrentDoc,
                  completeDocArrayState,
                  stateBooleans,
                  storeFunctions,
                  scrollToTop
                );
              }}
              data={stepperData}
            />
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={8} className={classes.document}>
        {isExpanded ? downloadSectionElement : imageElement}
      </Grid>
    </Grid>
  );
}

export default DocumentPage;
