import store from "./stores/Stepper";
var Reflux = require("reflux");
var actions = require("./actions/Stepper");

export default class Download extends Reflux.Component {
  constructor() {
    super();
    this.stores = [store];
  }

  componentDidMount() {
    var download_token = window.location.hash.replace("#/?", "");
    actions.downloadSignedFilesZip(download_token);
  }

  render() {
    return null;
  }
}
