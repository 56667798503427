import React from "react";
import { StoreProvider } from "easy-peasy";

import store from "../store";

const StoreStateProvider = ({ children }) => {
  return <StoreProvider store={store}>{children}</StoreProvider>;
};

export default StoreStateProvider;
