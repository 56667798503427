import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import StoreStateProvider from "./StoreStateProvider";
import RouterProvider from "./RouterProvider";
import LanguageProvider from "./LanguageProvider";
import iSignTheme from "../iSignTheme";

const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={iSignTheme}>
      <StoreStateProvider>
        <RouterProvider>
          <LanguageProvider>{children}</LanguageProvider>
        </RouterProvider>
      </StoreStateProvider>
    </ThemeProvider>
  );
};

export default Providers;
