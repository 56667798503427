import React from "react";
import Header from "./Header";
import { withRouter } from "react-router-dom";
import Footer from "./Footer";
import "./App.css";
import "./ErrorPage.css";
import { useTranslation } from "react-i18next";

function ErrorPage(props) {
  const { t } = useTranslation();
  const getErrCode = () => {
    return ('errorcode' in props.location) && props.error !== "404"
      ? props.location.errorcode
      : props.error;
  };
  return (
    <div className="App">
      <Header />
      <div className="errorstyle">
        <div className="errorcode-style">
          <h1>{getErrCode()}</h1>
        </div>
        <h2>{t(props.error + "errorTitle")}</h2>
        <p>{t(props.error + "errorMessage")}</p>
      </div>
      <div className="to-bottom">
        <Footer />
      </div>
    </div>
  );
}

export default withRouter(ErrorPage);
