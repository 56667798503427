import APIUtils from "../APIUtils";

/**
 * Get document object from get document index url API call.
 * @param {Response} response get document index url API response
 * @param {*} doc current document
 * @param {*} stateData store data for current contract
 * @param {*} self reference of state data object
 */
const getDocumentIndexURL = (response, doc, stateData, self) => {
  if (response.ok) {
    return new Promise((resolve) => {
      if (response) {
        response
          .json()
          .then((json) => resolve(json))
          .catch(() => resolve(null));
      } else {
        resolve(null);
      }
    });
  } else {
    console.log("Error while document index url api call");
    if (getAppUIValue(doc)) {
      setLargeFileState(stateData, self);
    }
    const errorMessage = {
      code: 400,
      message: "Error while getting document url",
    };
    throw errorMessage;
  }
};

/**
 * Load current document pages in state to display on screen.
 * @param {number} index current document index
 * @param {number} iIndex interedted document index
 * @param {*} doc current document
 * @param {*} stateData store data for current contract
 * @param {*} data Document object from getDocumentIndexURL response
 * @param {*} self reference of state data object
 */
const loadDocumentToDisplay = (index, iIndex, doc, stateData, data, self) => {
  if (index === iIndex && !stateData.firstDocDisplayed) {
    if (
      !getAppUIValue(doc) &&
      stateData.firstLargeFileDisableDisplay === false
    ) {
      firstDocImage(stateData, data.pages, self);
    } else {
      setLargeFileState(stateData, self);
    }
  }
};

export const getDocumentsIndexUrl = (stateData, docs, scid, sck, self) => {
  let iIndex = 0;
  let identified = false;
  const documentTypeValidationPatern = /ISN2-Contrat/;
  docs.forEach((doc, index) => {
    let intrestedDoc = isCorrectDocType(stateData.documentTypesArray, doc.type);
    const isDocumentToDownload =
      doc.index_url.match(documentTypeValidationPatern) === null;

    if (intrestedDoc && !identified) {
      iIndex = index;
      identified = true;
    }
    if (
      doc.index_url.length > 0 &&
      stateData.activeStep === 1 &&
      isDocumentToDownload
    ) {
      fetch(
        APIUtils("getDocumentIndexUrlInfo").replace("INDEX_URL", doc.index_url),
        {
          method: "get",
          headers: {
            "X-DICTAO-DTP-SCID": scid,
            "X-DICTAO-DTP-SCK": sck,
          },
        }
      )
        .then(function (response) {
          getDocumentIndexURL(response, doc, stateData, self).then(function (
            data
          ) {
            stateData.docIndexUrlMap.set(doc.name, data.pages);
            loadDocumentToDisplay(index, iIndex, doc, stateData, data, self);
          });
        })
        .catch((error) => {
          console.log("Error while document index url api call", error);
        });
    }
  });
};

export const getAppUIValue = (doc) => {
  let appUI = false;
  doc.tag.forEach((tag) => {
    if (tag.indexOf("appui:") !== -1 && tag.indexOf("true") !== -1) {
      appUI = true;
    }
  });
  return appUI;
};

export const setLargeFileState = (stateData, self) => {
  stateData.docImage = [];
  stateData.firstDocDisplayed = true;
  stateData.firstLargeFileDisableDisplay = true;
  self.trigger(self.data);
};

export const firstDocImage = (stateData, pages, self) => {
  stateData.docImage = addAllPagesToArray(pages);
  stateData.firstDocDisplayed = true;
  self.trigger(self.data);
};

export const addAllPagesToArray = (pages) => {
  const stateDocImagesArray = [];
  if (typeof pages !== "undefined") {
    pages.forEach((item) => {
      stateDocImagesArray.push(
        createDocImageArray(
          stateDocImagesArray.length,
          item.height,
          item.width,
          item.url
        )
      );
    });
  }
  return stateDocImagesArray;
};

export const createDocImageArray = (
  imgArrayLength,
  imgHeight,
  imgWidth,
  pngURL
) => {
  return {
    height: imgHeight,
    width: imgWidth,
    key: imgArrayLength,
    src: APIUtils("getDocumentImage").replace("PNG_URL", pngURL),
  };
};

export const isCorrectDocType = (docTypesArray, docType) => {
  let isCorrect = false;
  for (let documentType of docTypesArray) {
    if (docType === documentType) {
      isCorrect = true;
      break;
    }
  }
  return isCorrect;
};
