import { createStore, debug } from "easy-peasy";
import model from "./model";

const store = createStore(model);

if (process.env.NODE_ENV === "development") {
  console.log("STATE : ", debug(store.getState()));
  console.log("ACTIONS : ", debug(store.getActions()));
}

export default store;
