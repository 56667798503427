import React from "react";
import { Stepper } from "@dex/stepper";

function DocumentsStepper(props) {
  const { steps, color, isVertical, onClick } = props;

  const displayStepper = (
    <div style={{ paddingBottom: "20px", height: "100%" }}>
      <Stepper
        steps={steps}
        color={color}
        isVertical={isVertical}
        onClick={onClick}
      />
    </div>
  );

  return <>{displayStepper}</>;
}

export default DocumentsStepper;
