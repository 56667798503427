import { action } from "easy-peasy";

const documentsToSign = {
  areAllDocumentsViewed: false,
  areAllDocumentsRead: false,
  onlyAppuiDocuments: false,
  documentsToBeSign: [],
  areAllDocumentsReadOrViewed: false,

  setAreAllDocumentsViewed: action((state, payload) => {
    state.areAllDocumentsViewed = payload;
  }),
  setAreAllDocumentsRead: action((state, payload) => {
    state.areAllDocumentsRead = payload;
  }),
  setOnlyAppuiDocuments: action((state, payload) => {
    state.onlyAppuiDocuments = payload;
  }),
  setDocumentsToSign: action((state, payload) => {
    state.documentsToBeSign = payload;
  }),

  setAllDocumentsReadOrViewed: action((state) => {
    state.areAllDocumentsReadOrViewed = true;
  }),

  setAreAllDocumentsReadOrViewed: action((state) => {
    let viewDocuments = false;
    let readAllDocuments = false;
    if (state.documentsToBeSign) {
      state.documentsToBeSign.forEach((document) => {
        if (document.requirement === "view" || document.requirement === "none") {
          viewDocuments = true;
        } else if (document.requirement === "read") {
          readAllDocuments = true;
        }
      });
      if (!viewDocuments && readAllDocuments) {
        state.areAllDocumentsReadOrViewed = state.areAllDocumentsRead;
      } else if (viewDocuments && !readAllDocuments) {
        state.areAllDocumentsReadOrViewed = state.areAllDocumentsViewed;
      } else {
        state.areAllDocumentsReadOrViewed =
          state.areAllDocumentsRead && state.areAllDocumentsViewed;
      }
    }
  }),
};

export default documentsToSign;
