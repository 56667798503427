import documentsToSign from "./documentsToSign.model";
import userInfo from "./userInfo.model";

const model = {
  documentsToSign,
  userInfo,
};

// 👇 export the typed hooks
export default model;
