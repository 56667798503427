import React from "react";
import { useStoreActions } from "easy-peasy";

const LanguageProvider = ({ children }) => {
  const setLang = useStoreActions((actions) => actions.userInfo.setLanguage);

  if (
    window.location.hash.length > 0 &&
    (window.location.hash.indexOf("Login_") !== -1 ||
      window.location.hash.indexOf("Login") !== -1) &&
    window.location.hash.indexOf("lang=") !== -1
  ) {
    const defaultLangFromURL = window.location.hash.split("lang=")[1];
    setLang(defaultLangFromURL);
  }

  return <>{children}</>;
};

export default LanguageProvider;
