/* eslint-disable @typescript-eslint/no-namespace */
import React, { FunctionComponent } from "react";
import { useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "sgwt-mini-footer": {
        type: string;
        design: string;
        container: string;
        mode?: string;
        "legal-notices": any;
        "contact-us-by-help-center"?: string;
        "accessibility-compliance": string;
        "accessibility-link": string;
        "contact-us": any;
      };
    }
  }
}

const MiniFooter: FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const translation: any = useTranslation();
  const language: string = translation.i18n.language;
  const userName = useStoreState((state) => state.userInfo.user.name);
  const userEmail = useStoreState((state) => state.userInfo.user.mail);

  const legalNoticesMessages = {
    fr: [
      {
        label: t("disclaimer.title"),
        value: `${process.env.REACT_APP_BASE_NAME}/legal?lang=${language}&userName=${userName}&userEmail=${userEmail}`,
      },
      {
        label: t("TCU.title"),
        value: `${process.env.REACT_APP_BASE_NAME}/terms-of-use?lang=${language}&userName=${userName}&userEmail=${userEmail}`,
      },
      {
        label: t("dataProPolicy.title"),
        value: `${process.env.REACT_APP_BASE_NAME}/data-protection-policy?lang=${language}&userName=${userName}&userEmail=${userEmail}`,
      },
    ],
    en: [
      {
        label: t("disclaimer.title"),
        value: `${process.env.REACT_APP_BASE_NAME}/legal?lang=${language}&userName=${userName}&userEmail=${userEmail}`,
      },
      {
        label: t("TCU.title"),
        value: `${process.env.REACT_APP_BASE_NAME}/terms-of-use?lang=${language}&userName=${userName}&userEmail=${userEmail}`,
      },
      {
        label: t("dataProPolicy.title"),
        value: `${process.env.REACT_APP_BASE_NAME}/data-protection-policy?lang=${language}&userName=${userName}&userEmail=${userEmail}`,
      },
    ],
  };

  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      container="container"
      legal-notices={JSON.stringify(legalNoticesMessages)}
      accessibility-compliance="partial"
      accessibility-link={process.env.REACT_APP_BASE_NAME + "/accessibility"}
      contact-us={`{'mail': '${window.env.CONTACT_US}'}`}
    />
  );
};

export default MiniFooter;
