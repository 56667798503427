import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import InformationStep from "./components/InformationStep";
import StepperNavigator from "./components/StepperNavigator";
import DocumentsToSign from "./components/Documents/DocumentsToSign";
import ContractualClause from "./components/ContractualClause";
import UserConsent from "./components/UserConsent";
import LoadingPage from "./components/LoadingPage";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  tabComponent: {
    backgroundColor: "#f8f9fa",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: "20px",
    fontSize: "1.3rem",
    backgroundColor: theme.palette.primary.light,
  },
  h1Style: {
    margin: "24px 20px 24px 20px",
    textAlign: "left",
  },
  h2Style: {
    margin: "24px 20px 24px 20px",
    textAlign: "left",
  },
});

/**
 * Get first page of signature process (corresponding to form pedago step).
 * @param {*} storeData current contract data get from API
 * @param {*} classes list of css style classes
 */
const getFormPage = (storeData, classes) => {
  return storeData.stateData.currentActiveStep.isForm
    ? {
        step: <InformationStep data={storeData} className={classes.root} />,
        title: "electronicAgreement",
      }
    : { step: <LoadingPage data={storeData} />, title: "" };
};

/**
 * Get current page info from current active step.
 * @param {*} storeData current contract data det from API
 */
const getCurrentPage = (storeData) => {
  let step = <LoadingPage data={storeData} />;
  let title = "";
  if (storeData.stateData.currentActiveStep.isDocumentsToSign) {
    step = <DocumentsToSign data={storeData} />;
    title = "docsToSign";
  } else if (
    storeData.stateData.currentActiveStep.isRecap ||
    (storeData.stateData.currentActiveStep.isConvPreuv &&
      storeData.stateData.currentActiveStep.hasConvPreuvStep)
  ) {
    step = <ContractualClause data={storeData} />;
    title = "contractualClause";
  } else if (storeData.stateData.currentActiveStep.isOTP) {
    step = <UserConsent data={storeData} />;
    title = "userConsent";
  }
  return { step: step, title: title };
};

/**
 * Return current page elements to display on screen.
 * @param {*} storeData current contract data det from API
 * @param {*} classes list of css style classes
 */
const getCurrentPageData = (storeData, classes) => {
  if (storeData.stateData.loaded) {
    return storeData.stateData.activeStep === 0
      ? getFormPage(storeData, classes)
      : getCurrentPage(storeData);
  }
};

function MainStepper(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const caseData = props.data;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [caseData.stateData.activeStep]);

  const page = getCurrentPageData(caseData, classes);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
        <Grid item xs={12} sm={10} lg={10} xl={8}>
          <h1 className={classes.h1Style}>
            {caseData.userAccessInfo.case_name != null
              ? caseData.userAccessInfo.case_name
              : caseData.signatureUserAccessInfo.case_name}
          </h1>
          <h2 className={classes.h2Style}>{t(page.title)}</h2>
          <div className={classes.tabComponent}>{page.step}</div>
        </Grid>
        <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
      </Grid>

      <StepperNavigator data={props.data} />
    </div>
  );
}

export default withStyles(styles)(MainStepper);
