import APIUtils from "../APIUtils";
import {
  getDocumentsIndexUrl,
  addAllPagesToArray,
  isCorrectDocType,
} from "./DocumentRenderStore";
import {
  getSortedDocs,
  getCurrentSignatoryActivities,
} from "../services/consentUtils";
import { toast } from "react-toastify";
var Reflux = require("reflux");
var actions = require("../actions/Stepper");
var moment = require("moment");

export default class Stepper extends Reflux.Store {
  constructor() {
    super();
    this.listenables = actions;
    let defaultData = {
      language: "",
      systemIp: "",
      tokenResponse: {},
      consultationActivityStatus: "",
      activity: {},
      userAccessInfo: {},
      accessId: {},
      channel: {},
      stateData: {
        documentTypesArray: [
          "ISN-Contrat",
          "ISN2-Contrat",
          "ISN_SPI-SGN-Doc1",
          "ISN_SPI-SGN-Conv-Preuv",
          "ISN_SPI-SGN-Doc2",
          "ISN_SPI-SGN-Doc3",
          "ISN_SPI-SGN-Doc4",
          "ISN_SPI-SGN-Doc5",
          "ISN_SPI-SGN-Doc6",
          "ISN_SPI-SGN-Doc7",
          "ISN_SPI-SGN-Doc8",
          "ISN_SPI-SGN-Doc9",
          "ISN_SPI-SGN-Doc10",
          "ISN_SPI-SGN-Doc11",
          "ISN_SPI-SGN-Doc12",
          "ISN_SPI-SGN-LienPPPM",
          "ISN-SPI-FAR-Auto-Certification",
          "ISN-SPI-FAR-Conv-Preuv",
          "ISN-SPI-CARTE-AFF-CP",
          "ISN-SPI-CARTE-AFF-CG",
          "ISN-SPI-CARTE-LienPPPM",
          "ISN-SPI-CARTE-AFF-Conv-Preuv",
        ],
        signatory: "",
        previousStepCounter: 0,
        consentProvidedText: new Map(),
        needUpdate: true,
        isNextButtonActivated: false,
        isNextButtonLoading: false,
        isCheckboxTicked: false,
        isUpdatingStepStatus: false,
        isSignatoryAuthenticated: false,
        currentActiveStep: {
          hasConvPreuvStep: false,
          isForm: false,
          isDocumentsToSign: false,
          isRecap: false,
          isConvPreuv: false,
          isOTP: false,
          isDocumentsToSignAlreadyDisplayed: false,
          isRecapAlreadyDisplayed: false,
          isConvPreuvAlreadyDisplayed: false,
        },
        previousActiveStep: new Map(),
        loaded: false,
        viewId: "dictao-dtp-bpm_4.2.8.0",
        timeout: 3600,
        consentProvided: new Map(),
        docConsentProvided: [],
        totalDocToSign: -1,
        tcuConsentProvided: false,
        sendOtpStatus: "",
        validateOtpStatus: "",
        activeStep: 0,
        activeSubStep: 0,
        stepToLoadDataFrom: 0,
        finished: false,
        signAlreadyCompleted: false,
        tokenNotTranslated: false,
        signatureDataLoaded: false,
        otpProvided: "",
        otpCounter: 0,
        signReadyToComplete: false,
        docImage: [],
        docIndexUrlInfo: {},
        docIndexUrlMap: new Map(),
        signingDoc: [],
        informativeDoc: [],
        firstDocDisplayed: false,
        firstLargeFileDisableDisplay: false,
        contractCancelled: false,
        contractExpired: false,
        contractInError: false,
        loadingContractDataFailed: false,
      },
      signatureActivity: {},
      signatureAccessId: {},
      signatureUserAccessInfo: {},
      signatureChannel: {},
      certificate: {},
      sendOtpResponse: {},
      isignSendOtpResponse: {},
      validateOtpResponse: {},
      isignValidateOtpResponse: {},
      errors: [],
      success: [],
      login: {},
    };
    this.data = defaultData;

    this.setState(defaultData);
  }

  //===========================================================================//
  //============================= TOAST PARAMETERS ============================//
  //===========================================================================//

  toastParameters = () => {
    return {
      theme: "colored",
      autoClose: 7000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
    };
  };

  serverNotReachableToast = () => {
    toast.error(
      this.translationContent().serverNotReachable,
      this.toastParameters()
    );
  };

  //===========================================================================//
  //============================ REUSABLE API CALLS ===========================//
  //===========================================================================//

  async createChannelAPICall(access_id, type, channelPayload) {
    const self = this;
    return fetch(APIUtils("createChannel").replace("ACCESS_ID", access_id), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(channelPayload),
    }).then(function (response) {
      if (response.ok) {
        return new Promise((resolve) => {
          if (response) {
            response
              .json()
              .then((json) => resolve(json))
              .catch(() => resolve(null));
          } else {
            resolve(null);
          }
        });
      } else {
        let errorMessage = "";
        switch (type) {
          case "first_create_channel":
            errorMessage = self.translationContent().createChannelErrorFCC;
            break;
          case "create_channel":
            errorMessage = self.translationContent().createChannelErrorCC;
            break;
          case "recreate_channel":
            errorMessage = self.translationContent().createChannelErrorRCC;
            break;
          default:
            console.log(type + " not in the switch list");
        }
        toast.error(errorMessage, self.toastParameters());
        self.trigger(self.data);
      }
    });
  }

  async userSpaceAuthentication(activity, type) {
    const self = this;
    return fetch(
      APIUtils("userSpaceAuthentication")
        .replace("SIGNATORY_REF", self.data.tokenResponse.signatoryRef)
        .replace(
          "CONTEXT_URL",
          Stepper.getActivityParameter(activity, type, "context_url")
        ),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      }
    ).then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        let errorMessage = "";
        switch (type) {
          case "consultation":
            errorMessage =
              self.translationContent().userSpaceAuthenticationError;
            break;
          case "signature_session":
            errorMessage =
              self.translationContent().userSpaceAuthenticationErrorUSI;
            break;
          default:
            console.log(type + " is not in the switch list.");
        }
        toast.error(errorMessage, self.toastParameters());
        self.trigger(self.data);
      }
    });
  }

  async getPendingSignatoryActivity(case_ref, type) {
    return fetch(
      APIUtils("getPendingSignatureActivity")
        .replace("CASE_REF", case_ref)
        .replace("TYPE", type),
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        let errorMessage = "";
        switch (type) {
          case "consultation":
            errorMessage =
              this.translationContent().getPendingSignatoryActivityErrorGCA;
            break;
          case "signature_session":
            errorMessage =
              this.translationContent().getPendingSignatoryActivityErrorGPA;
            break;
          default:
            console.log(type + " is not in the switch list.");
        }
        toast.error(errorMessage, this.toastParameters());
      }
      this.trigger(this.data);
    });
  }

  async acceptOngoingTask() {
    const self = this;
    return fetch(
      APIUtils("acceptOngoingTask").replace(
        "TASK_ID",
        Stepper.getActivityParameter(
          self.data.activity,
          "consultation",
          "identifier"
        )
      ),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-DICTAO-DTP-SCID": self.data.channel.scid,
          "X-DICTAO-DTP-SCK": self.data.channel.sck,
        },
      }
    )
      .then(function (response) {
        if (response.ok) {
          return "success";
        } else {
          toast.error(
            self.translationContent().acceptOngoingTaskError,
            self.toastParameters()
          );
        }
      })
      .catch((error) => {
        self.serverNotReachableToast();
      });
  }

  async saveConsent(token, scid, sck, type, payload, docName) {
    const self = this;
    return fetch(APIUtils("saveConsent").replace("PATH_PARAM", token), {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-DICTAO-DTP-SCID": scid,
        "X-DICTAO-DTP-SCK": sck,
      },
      body: JSON.stringify(payload),
    }).then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        let errorMessage = "";
        switch (type) {
          case "consentProvided":
            self.setConsentProvided(false);
            errorMessage = self.translationContent().saveConsentErrorCP;
            break;
          case "recapConsentProvided":
            self.setConsentProvided(false);
            errorMessage = self.translationContent().saveConsentErrorRCP;
            break;
          case "docConsentProvided":
            self.data.stateData.docConsentProvided = self.arrayRemove(
              self.data.stateData.docConsentProvided,
              docName
            );
            errorMessage = self.translationContent().saveConsentErrorDCP;
            break;
          case "tcuConsentProvided":
            self.data.stateData.tcuConsentProvided = false;
            errorMessage = self.translationContent().saveConsentErrorTCP;
            break;
          case "otpConsentProvided":
            self.data.stateData.otpConsentProvided = false;
            console.log("Error in otp consent api.");
            errorMessage = self.translationContent().saveConsentErrorOCP;
            break;
          default:
            console.log(type + " is not in the switch list.");
        }
        toast.error(errorMessage, self.toastParameters());
        self.trigger(self.data);
      }
    });
  }

  async generateCertificateAPICall(access_id, scid, sck, type) {
    const self = this;
    return fetch(
      APIUtils("generateCertificate").replace("ACCESS_ID", access_id),
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-DICTAO-DTP-SCID": scid,
          "X-DICTAO-DTP-SCK": sck,
        },
      }
    ).then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        let errorMessage = "";
        switch (type) {
          case "generate_certificate":
            errorMessage =
              self.translationContent().generateCertificateErrorCGF;
            break;
          case "regenerate_certificate":
            errorMessage =
              self.translationContent().generateCertificateErrorRCG;
            break;
          default:
            console.log(type + " is not in the switch list.");
        }
        toast.error(errorMessage, self.toastParameters());
        self.trigger(self.data);
      }
    });
  }

  async acceptCertificateAPICall(access_id, scid, sck, payload) {
    return fetch(
      APIUtils("acceptCertificate").replace("ACCESS_ID", access_id),
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "X-DICTAO-DTP-SCID": scid,
          "X-DICTAO-DTP-SCK": sck,
        },
        body: JSON.stringify(payload),
      }
    );
  }

  //===========================================================================//
  //================================= FUNCTIONS ===============================//
  //===========================================================================//

  onSetLanguage(language) {
    this.data.language = language;
    this.trigger(this.data);
  }

  translationContent = () => {
    let json;
    if (this.data.language === "en") {
      json = require("../toastMessages/en/translation.json");
    } else {
      json = require("../toastMessages/fr/translation.json");
    }
    return json;
  };

  //starting test
  onCreateFirstChannel() {
    const channelPayload = { viewId: "dictao-dtp-bpm_4.2.8.0", timeout: 3600 };
    const self = this;
    self
      .createChannelAPICall(
        self.data.userAccessInfo.access_id,
        "first_create_channel",
        channelPayload
      )
      .then(function (data) {
        if (data) {
          self.data.channel = data;
          self.data.stateData.loaded = true;
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  getActivityStatus(activities, type) {
    let completedActivitiesCounter = 0;
    const currentSignatoryActivities = getCurrentSignatoryActivities(
      activities,
      this.data.stateData.signatory
    );
    for (let currentSignatoryActivity of currentSignatoryActivities) {
      if (
        currentSignatoryActivity.type === type &&
        currentSignatoryActivity.context_url.search(
          this.data.tokenResponse.signatoryRef
        ) !== -1
      ) {
        if (currentSignatoryActivity.status === "completed") {
          completedActivitiesCounter++;
        } else {
          return currentSignatoryActivity.status;
        }
      }
    }
    if (currentSignatoryActivities.length === completedActivitiesCounter) {
      return "completed";
    } else {
      return null;
    }
  }

  onGetInitialState(payload) {
    const self = this;
    fetch(APIUtils("tokenTranslation"), {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          self.data.stateData.loaded = true;
          self.data.stateData.tokenNotTranslated = true;
          self.trigger(self.data);
        }
      })
      .then(function (data) {
        if (data) {
          self.data.tokenResponse = data;
          var expDate = moment(data.expiryDate).toDate();
          var today = new Date();
          console.log("today", today);
          console.log("Contract expiryDate", expDate);
          if (expDate < today) {
            self.data.stateData.loaded = true;
            self.data.stateData.contractExpired = true;
            self.trigger(self.data);
          } else {
            self.getConsultationActivity();
          }
        } else {
          self.data.stateData.loaded = true;
          self.data.stateData.tokenNotTranslated = true;
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.data.stateData.loaded = true;
        self.data.stateData.tokenNotTranslated = true;
        self.trigger(self.data);
      });
  }

  onSetCurrentActiveSubStep(value) {
    const self = this;
    self.data.stateData.activeSubStep = value;
    self.trigger(self.data);
  }

  setCurrentSignatory(activities) {
    const self = this;
    const login = self.data.tokenResponse.signatoryRef;
    for (let activity of activities) {
      if (activity.context_url.includes(login)) {
        self.data.stateData.signatory = activity.role_ref;
        break;
      }
    }
    self.trigger(self.data);
  }

  getConsultationActivity() {
    const self = this;
    self
      .getPendingSignatoryActivity(
        self.data.tokenResponse.transactionId,
        "consultation"
      )
      .then(function (data) {
        if (data) {
          self.data.activity = data;
          self.setCurrentSignatory(data);
          const status = self.getActivityStatus(data, "consultation");
          if (status === "active") {
            self.authenticateSignatoryOnConsultation();
          } else if (status === "completed") {
            self.data.stateData.activeStep = 1;
            self.setConsentProvided(true);
            self.data.stateData.loaded = true;
            self.data.stateData.stepToLoadDataFrom = 1;
            self.trigger(self.data);
          } else if (status === "aborted") {
            self.data.stateData.loaded = true;
            self.data.stateData.contractCancelled = true;
            self.trigger(self.data);
          } else if (status === "error") {
            self.data.stateData.loaded = true;
            self.data.stateData.contractInError = true;
            self.trigger(self.data);
          } else {
            self.data.stateData.finished = true;
            self.data.stateData.activeStep = 3;

            self.trigger(self.data);
          }
        }
      })
      .catch((error) => {
        self.data.stateData.loaded = true;
        self.data.stateData.loadingContractDataFailed = true;
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  authenticateSignatoryOnConsultation() {
    const self = this;
    self
      .userSpaceAuthentication(self.data.activity, "consultation")
      .then(function (data) {
        if (data) {
          self.data.userAccessInfo = data;
          self.data.accessId = data.access_id;
          self.trigger(self.data);
          self.onCreateFirstChannel();
        }
      })
      .catch((error) => {
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  static getActivityParameter(activities, type, parameter) {
    for (let activity of activities) {
      if (activity.type === type) {
        return activity[`${parameter}`];
      }
    }
  }

  authenticateSignatory() {
    const cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)set-cookies\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    console.log("cookies value authentication----------", cookieValue);
    const self = this;
    self
      .userSpaceAuthentication(self.data.signatureActivity, "signature_session")
      .then(function (data) {
        if (data) {
          const documentTypesArray = self.data.stateData.documentTypesArray;
          self.data.signatureUserAccessInfo = data;
          self.updateCurrentDisplayPage(data);
          //ISN-Contrat,ISN2-Contrat,ISN_SPI-SGN-Doc1,ISN_SPI-SGN-Conv-Preuv,ISN_SPI-SGN-Doc2,ISN_SPI-SGN-Doc3,ISN_SPI-SGN-LienPPPM
          self.data.stateData.signingDoc = data.documents.filter(
            (doc) =>
              isCorrectDocType(documentTypesArray, doc.type) &&
              doc.placeholder === false &&
              //doc.content_uploaded === true &&
              doc.index_url.length > 0
          );
          self.data.stateData.informativeDoc = data.documents.filter(
            (doc) =>
              !isCorrectDocType(documentTypesArray, doc.type) &&
              //doc.content_uploaded === true &&
              doc.index_url.length > 0
          );

          var contract = data.documents.filter(function (item) {
            /**MKP : ISN-Contrat || ISN2-contrat ||
             * SPIDER : ISN-Conv-Preuv || ISN_SPI-SGN-Doc1 ||
             */
            return isCorrectDocType(documentTypesArray, item.type);
          });
          self.data.stateData.totalDocToSign = contract.length;
          self.data.stateData.signatureDataLoaded = true;
          let channelPayload = {
            viewId: "dictao-dtp-bpm_4.2.8.0",
            timeout: 3600,
          };
          actions.createChannel(channelPayload);
          self.onSetNextButtonLoading(false);
          self.onSetNextButtonActiveState(true);
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  onLoadDocumentsToSign() {
    this.getPendingActivity();
  }

  isApiResponseValid(data) {
    if (data && data.length > 0) {
      for (let info of data) {
        if (
          info.context_url &&
          info.context_url.search(this.data.tokenResponse.signatoryRef) !== -1
        ) {
          return true;
        }
      }
      return false;
    } else return false;
  }

  /**
   * Update contract status if signature session is finished.
   * @param {*} data signatory pending activity object
   * @param {NodeJS.Timer} intervalId id of current open interval for API calls
   */
  updateContractStatus = (data, intervalId) => {
    const self = this;
    if (self.isApiResponseValid(data)) {
      clearInterval(intervalId);
      self.setCurrentSignatory(data);
      self.data.signatureActivity = data;
      //IN case signature process is already completed
      const activityStatus = self.getActivityStatus(
        self.data.signatureActivity,
        "signature_session"
      );
      switch (activityStatus) {
        case "completed":
          self.data.stateData.signAlreadyCompleted = true;
          break;
        case "aborted":
          self.data.stateData.contractCancelled = true;
          break;
        case "error":
          self.data.stateData.contractInError = true;
          break;
        default:
          if (!self.data.stateData.isSignatoryAuthenticated) {
            self.authenticateSignatory();
            self.data.stateData.isSignatoryAuthenticated = true;
          }
          break;
      }
      self.trigger(self.data);
    } else {
      self.getPendingActivity();
    }
  };

  getPendingActivity() {
    const self = this;
    let isToastAlreadyDisplayed = false;
    let tokenResponse = this.data.tokenResponse.transactionId;
    const intervalId = setInterval(function awaitForNextActivityToBeReady() {
      self
        .getPendingSignatoryActivity(tokenResponse, "signature_session")
        .then(function (data) {
          if (data?.length && data[0].status === "error") {
            clearInterval(intervalId);
            self.data.stateData.contractInError = true;
            self.trigger(self.data);
          } else {
            self.updateContractStatus(data, intervalId);
            self.trigger(self.data);
          }
        })
        .catch((error) => {
          clearInterval(intervalId);
          if (!isToastAlreadyDisplayed) {
            self.serverNotReachableToast();
            isToastAlreadyDisplayed = true;
            self.data.stateData.loadingContractDataFailed = true;
            self.trigger(self.data);
          }
        });
    }, 2000);
  }

  onSetConsentProvidedText(consentProvidedText) {
    const self = this;
    const currentStep = self.data.stateData.activeStep;
    let isPresent = false;
    let text;
    if (self.data.stateData.consentProvidedText.size > 0) {
      text = self.data.stateData.consentProvidedText.get(currentStep);
      if (text !== undefined) {
        isPresent = true;
      } else {
        text = consentProvidedText;
      }
    } else {
      text = consentProvidedText;
    }
    if (!isPresent) {
      self.data.stateData.consentProvidedText.set(currentStep, text);
    }
    self.trigger(self.data);
  }

  getConsentProvidedTextOnCurrentStep() {
    const self = this;
    const currentStep = self.data.stateData.activeStep;
    return self.data.stateData.consentProvidedText.get(currentStep);
  }

  setConsentProvided(isConsentProvided) {
    const self = this;
    const currentStep = self.data.stateData.activeStep;
    let isPresent = false;
    let consent;
    if (self.data.stateData.consentProvided.size > 0) {
      consent = self.data.stateData.consentProvided.get(currentStep);
      if (consent !== undefined) {
        isPresent = true;
      } else {
        consent = isConsentProvided;
      }
    } else {
      consent = isConsentProvided;
    }
    if (!isPresent) {
      self.data.stateData.consentProvided.set(currentStep, consent);
    }
    self.trigger(self.data);
  }

  isConsentProvidedOnCurrentStep() {
    const self = this;
    const currentStep = self.data.stateData.activeStep;
    if (self.data.stateData.consentProvided.get(currentStep) === undefined) {
      return false;
    } else {
      return self.data.stateData.consentProvided.get(currentStep);
    }
  }

  async onConsentProvided(payload) {
    const self = this;
    return self
      .saveConsent(
        self.data.userAccessInfo.access_id,
        self.data.channel.scid,
        self.data.channel.sck,
        "consentProvided",
        payload,
        ""
      )
      .catch((error) => {
        self.setConsentProvided(true);
        self.serverNotReachableToast();
      });
  }

  async onRecapConsentProvided(payload) {
    const self = this;
    return self
      .saveConsent(
        self.data.signatureUserAccessInfo.access_id,
        self.data.signatureChannel.scid,
        self.data.signatureChannel.sck,
        "recapConsentProvided",
        payload,
        ""
      )
      .catch((error) => {
        self.setConsentProvided(true);
        self.serverNotReachableToast();
      });
  }

  updatePreviousStepCounterValue(value) {
    const self = this;
    if (self.data.stateData.previousStepCounter > 0) {
      self.data.stateData.previousStepCounter =
        self.data.stateData.previousStepCounter + value;
    }
    self.trigger(self.data);
  }

  onSetNextButtonActiveState(isActivated) {
    this.data.stateData.isNextButtonActivated = isActivated;
    this.trigger(this.data);
  }

  onSetNextButtonLoading(isLoading) {
    const self = this;
    self.data.stateData.isNextButtonLoading = isLoading;
    self.trigger(self.data);
  }

  onSetCheckboxTickedState(isChecked) {
    this.data.stateData.isCheckboxTicked = isChecked;
    this.trigger(this.data);
  }

  /**
   * Define if previous step is same from pedago step as current one.
   * @param {*} currentActiveStep current step displayed on sceen
   * @param {*} previousActiveStep previous step
   */
  isSameFormStep = (currentActiveStep, previousActiveStep) => {
    return currentActiveStep.isForm === previousActiveStep.isForm;
  };

  /**
   * Define if previous step is same Document to sign step as current one.
   * @param {*} currentActiveStep current step displayed on sceen
   * @param {*} previousActiveStep previous step
   */
  isSameDocumentToSignStep = (currentActiveStep, previousActiveStep) => {
    return (
      currentActiveStep.isDocumentsToSign ===
      previousActiveStep.isDocumentsToSign
    );
  };

  /**
   * Define if previous step is same recap step as current one.
   * @param {*} currentActiveStep current step displayed on sceen
   * @param {*} previousActiveStep previous step
   */
  isSameRecapStep = (currentActiveStep, previousActiveStep) => {
    if (!currentActiveStep.hasConvPreuvStep) {
      if (currentActiveStep.isRecap === previousActiveStep.isRecap) {
        return true;
      }
    } else {
      if (currentActiveStep.isConvPreuvAlreadyDisplayed) {
        if (currentActiveStep.isRecap === previousActiveStep.isConvPreuv) {
          return true;
        }
        return false;
      } else {
        if (currentActiveStep.isConvPreuv === previousActiveStep.isConvPreuv) {
          return true;
        }
        return false;
      }
    }
  };

  /**
   * Define if previous step is same OTP step as current one.
   * @param {*} currentActiveStep current step displayed on sceen
   * @param {*} previousActiveStep previous step
   */
  isSameOtpStep = (currentActiveStep, previousActiveStep) => {
    return currentActiveStep.isOTP === previousActiveStep.isOTP;
  };

  isPreviousStepSameAsCurrentOne(previousActiveStep) {
    const self = this;
    const currentActiveStep = self.data.stateData.currentActiveStep;
    const hasSameFormStep = self.isSameFormStep(
      currentActiveStep,
      previousActiveStep
    );
    const hasSameDocumentToSignStep = self.isSameDocumentToSignStep(
      currentActiveStep,
      previousActiveStep
    );
    const hasSameRecapStep = self.isSameRecapStep(
      currentActiveStep,
      previousActiveStep
    );
    const hasSameOtpStep = self.isSameOtpStep(
      currentActiveStep,
      previousActiveStep
    );
    if (previousActiveStep !== undefined) {
      return (
        hasSameFormStep &&
        hasSameDocumentToSignStep &&
        hasSameRecapStep &&
        hasSameOtpStep
      );
    }
    return false;
  }

  /**
   * Update state if document to sign page has already been displayed.
   */
  setDocumentsToSignAlreadyDisplayed = () => {
    const self = this;
    if (
      !self.data.stateData.currentActiveStep
        .isDocumentsToSignAlreadyDisplayed &&
      self.data.stateData.currentActiveStep.isDocumentsToSign
    ) {
      self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed = true;
    }
  };

  /**
   * Update state if recap page has already been displayed.
   */
  setRecapAlreadyDisplayed = () => {
    const self = this;
    if (
      !self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed &&
      self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed
    ) {
      if (self.data.stateData.currentActiveStep.hasConvPreuvStep) {
        if (
          self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed &&
          self.data.stateData.currentActiveStep.isRecap
        ) {
          self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed = true;
        }
      } else {
        if (self.data.stateData.currentActiveStep.isRecap) {
          self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed = true;
        }
      }
    }
  };

  /**
   * Update state if conv preuv page has already been displayed.
   */
  setConvPreuvAlreadyDisplayed = () => {
    const self = this;
    if (
      !self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed &&
      self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed &&
      self.data.stateData.currentActiveStep.isConvPreuv
    ) {
      self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed = true;
    }
  };

  updateCurrentActiveStepOnChange() {
    const self = this;
    self.setDocumentsToSignAlreadyDisplayed();
    self.setRecapAlreadyDisplayed();
    self.setConvPreuvAlreadyDisplayed();
    self.data.stateData.currentActiveStep.isForm = false;
    self.data.stateData.currentActiveStep.isDocumentsToSign = false;
    self.data.stateData.currentActiveStep.isRecap = false;
    self.data.stateData.currentActiveStep.isConvPreuv = false;
    self.trigger(self.data);
  }

  hasConvPreuvToDisplay() {
    const self = this;
    if (self.data.stateData.currentActiveStep.hasConvPreuvStep) {
      return !self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed;
    } else {
      return false;
    }
  }

  /**
   * Update isForm parameter if current clause correspond to form pedago step.
   * @param {*} clause list of html element to display on screen
   */
  hasSameFromStepParameter = (clause) => {
    const self = this;
    if (clause.document_type_ref.includes("Form-")) {
      self.data.stateData.currentActiveStep.isForm = true;
    }
  };

  /**
   * Update isDocumentsToSign parameter if current clause correspond to document to sign step.
   * @param {*} clause list of html element to display on screen
   */
  updateIsDocumentToSign = (clause) => {
    const self = this;
    if (
      clause.document_type_ref.includes("Recap") &&
      !self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed
    ) {
      self.data.stateData.currentActiveStep.isDocumentsToSign = true;
    }
  };

  /**
   * Define id current signing process has conv preuv step.
   * @param {*} clause list of html element to display on screen
   */
  getHasConvPreuv = (clause) => {
    const self = this;
    if (clause.document_type_ref.includes("-Clause")) {
      self.data.stateData.currentActiveStep.hasConvPreuvStep = true;
      return true;
    } else {
      return false
    }
  };

  /**
   * Update isConvPreuv parameter if current clause correspond to conv preuv step.
   * @param {*} clause list of html element to display on screen
   */
  updateIsConvPreuv = (clause) => {
    const self = this;
    if (
      clause.document_type_ref.includes("-Clause") &&
      !self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed &&
      self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed &&
      !self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed &&
      !self.data.stateData.currentActiveStep.isOTP
    ) {
      self.data.stateData.currentActiveStep.isConvPreuv = true;
    }
  };

  /**
   * Update isRecap parameter if current clause correspond to recap step.
   * @param {*} clause list of html element to display on screen
   */
  updateIsRecap = (clause) => {
    const self = this;
    if (
      clause.document_type_ref.includes("Recap") &&
      self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed &&
      !self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed &&
      !self.data.stateData.currentActiveStep.isOTP
    ) {
      if (self.data.stateData.currentActiveStep.hasConvPreuvStep) {
        if (self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed) {
          self.data.stateData.currentActiveStep.isRecap = true;
        }
      } else {
        self.data.stateData.currentActiveStep.isRecap = true;
      }
    }
  };

  /**
   * Update isOTP parameter if current clause correspond to OTP step. All others parameters
   * are set to false to avoid any side effect while displaying last page.
   * @param {*} clause list of html element to display on screen
   */
  updateIsOTPStep = () => {
    const self = this;
    if (
      self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed &&
      self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed &&
      !self.hasConvPreuvToDisplay() &&
      self.data.validateOtpStatus !== "VALIDATE_OTP_SUCCESS"
    ) {
      self.data.stateData.currentActiveStep.isForm = false;
      self.data.stateData.currentActiveStep.isDocumentsToSign = false;
      self.data.stateData.currentActiveStep.isRecap = false;
      self.data.stateData.currentActiveStep.isConvPreuv = false;
      self.data.stateData.currentActiveStep.isOTP = true;
    }
  };

  async updateCurrentDisplayPage(object) {
    const self = this;
    if (Object.keys(object).length > 0) {
      if (object.consent && object.consent.clauses) {
        object.consent.clauses.forEach((clause) => {
          self.hasSameFromStepParameter(clause);
          self.updateIsDocumentToSign(clause);
          self.getHasConvPreuv(clause);
          self.updateIsConvPreuv(clause);
          self.updateIsRecap(clause);
          self.updateIsOTPStep();
        });
      }
    }
    self.data.stateData.needUpdate = false;
    self.onSetNextButtonActiveState(true);
    self.trigger(self.data);
  }

  getNextActivityClauseFromState() {
    const self = this;
    const currentStep = self.data.stateData.currentStep;
    const currentSubStep = self.data.stateData.currentSubStep;
    let clauses;
    if (self.getStepFromState(currentStep, currentSubStep + 1) !== undefined) {
      clauses = self.getStepFromState(currentStep, currentSubStep + 1).clauses;
    } else {
      clauses = self.getStepFromState(currentStep + 1, 0).clauses;
    }
    return clauses;
  }

  getActivitiesNumberRelatedToCurrentSignatory(data) {
    const self = this;
    let counter = 0;
    data.forEach((activity) => {
      if (activity.role_ref === self.data.stateData.signatory) {
        counter++;
      }
    });
    return counter;
  }

  hasPreviousStepCompleted = (currentSignatoryActivities) => {
    let currentSignatoryCompletedActivitiesCounter = 0;
    for (let currentSignatoryActivity of currentSignatoryActivities) {
      if (currentSignatoryActivity.status === "completed") {
        currentSignatoryCompletedActivitiesCounter++;
      }
    }
    return (
      currentSignatoryCompletedActivitiesCounter ===
      currentSignatoryActivities.length
    );
  };

  waitForPreviousStepToBeCompleted() {
    const self = this;
    let isToastAlreadyDisplayed = false;
    const intervalId = setInterval(function awaitForNextActivityToBeReady() {
      self
        .getPendingSignatoryActivity(
          self.data.tokenResponse.transactionId,
          "consultation"
        )
        .then((data) => {
          if (data) {
            const currentSignatoryActivities = getCurrentSignatoryActivities(
              data,
              self.data.stateData.signatory
            );
            const isPreviousActivityCompleted = self.hasPreviousStepCompleted(
              currentSignatoryActivities
            );
            if (isPreviousActivityCompleted) {
              clearInterval(intervalId);
              self.data.stateData.isUpdatingStepStatus = false;
              self.updateNewActivity(currentSignatoryActivities);
            }
          } else {
            clearInterval(intervalId);
            if (!isToastAlreadyDisplayed) {
              toast.error(
                self.translationContent().getPendingActivityError,
                this.toastParameters()
              );
              isToastAlreadyDisplayed = true;
              self.data.stateData.loadingContractDataFailed = true;
              self.trigger(self.data);
            }
          }
        });
    }, 2000);
  }

  async updateNewActivity(activity) {
    const self = this;
    if (
      self.getActivitiesNumberRelatedToCurrentSignatory(activity) >
      self.data.stateData.activeSubStep + 1
    ) {
      try {
        await self
          .userSpaceAuthentication(self.data.activity, "consultation")
          .then(function (data) {
            if (data) {
              self.data.userAccessInfo = data;
              self.trigger(self.data);
              self.onCreateFirstChannel();
              self.onNext(data);
            }
          });
      } catch (e) {
        toast.error(
          self.translationContent().updateNewActivityError,
          this.toastParameters()
        );
      }
    } else {
      if (self.isConsentProvidedOnCurrentStep()) {
        self.getPendingActivity();
        self.onNext(self.data.signatureUserAccessInfo);
      }
    }
  }

  /**
   * Retrieve user info object from state.
   */
  getUserInfo = () => {
    const self = this;
    if (
      self.data.userAccessInfo.consent &&
      self.data.signatureUserAccessInfo.consent === undefined
    ) {
      return self.data.userAccessInfo;
    } else {
      return self.data.signatureUserAccessInfo;
    }
  };

  /**
   * Define if consent has been provied on first form pedago page to allow user to
   * continue signing process.
   * @param {*} data object returned by consent provided API call
   */
  sendFirstAgreementConsent = (data) => {
    const self = this;
    if (data) {
      self.setConsentProvided(true);
      self.acceptOngoingTask().then(async () => {
        self.waitForPreviousStepToBeCompleted();
      });
    } else {
      self.setConsentProvided(false);
      toast.error(
        self.translationContent().consentProvidedErrorCP,
        self.toastParameters()
      );
    }
  };

  /**
   * Define if consent has been provied on form pedago pag to allow user to
   * continue signing process.
   * @param {*} data object returned by consent provided API call
   */
  sendAgreementConsent = (data, state) => {
    const self = this;
    if (data) {
      self.setConsentProvided(true);
      self.onNext(state);
    } else {
      self.setConsentProvided(false);
      toast.error(
        self.translationContent().consentProvidedErrorRCP,
        self.toastParameters()
      );
    }
  };

  /**
   * Display the next activity depending on the content of consent
   * object in state. If user goes back with previous button, data
   * is loaded directly from state.
   */
  async onNextActivity() {
    const self = this;
    // Get consent text for current step if it exists
    const payload = {
      text: self.data.stateData.consentProvidedText.get(
        self.data.stateData.activeStep
      ),
    };
    let isConsultation =
      self.data.userAccessInfo.consent &&
        self.data.signatureUserAccessInfo.consent === undefined
        ? true
        : false;
    const state = self.getUserInfo();
    // Set current activity info in state if user goes back.
    self.setPreviousActivityData(state);
    // Update substep if needed on change.
    self.updateCurrentActiveStepOnChange();
    // Reset checkbox state.
    self.onSetCheckboxTickedState(false);
    // Reset next button state.
    self.onSetNextButtonActiveState(false);
    // Check if current step has already been displayed. previousStepCounter > 0
    // means that user used back button and next activity will be loaded from state.
    if (self.data.stateData.previousStepCounter === 0) {
      // Check if consent text is valid and if consent has been send. If consent
      // text is not empty and consent not provided, it means that consent have to.
      // be sent.
      if (
        payload.text !== undefined &&
        !self.isConsentProvidedOnCurrentStep()
      ) {
        // Check if current step is the first one, where acceptOngoingTask() allows
        // to go from userAccessInfo (consultation) to signatureUserAccessInfo
        // (signature_session).
        if (self.data.stateData.activeStep === 0) {
          self.data.stateData.isUpdatingStepStatus = true;
          self.trigger(self.data);
          // Send consent for 1rst step and move to next one.
          self.onConsentProvided(payload).then((data) => {
            self.sendFirstAgreementConsent(data);
          });
        } else {
          // Send consent for 2nd recap step and move to next one.
          self.onRecapConsentProvided(payload).then(function (data) {
            self.sendAgreementConsent(data, state);
          });
        }
        // If consent text is empty and consent not given, it means that current
        // step does not require any consent management and user can move to next
        // step.
      } else {
        self.onNext(state);
      }
      // User is using next button after having used previous one, then it loads
      // data from state to display step info.
    } else {
      // Set clauses to display correct info on srceen depending on the step.
      isConsultation
        ? self.setClauses("consultation", self.getNextActivityClauseFromState())
        : self.setClauses(
          "signature_session",
          self.getNextActivityClauseFromState()
        );
      // Use new clauses to move to next step.
      self.onNext(state);
    }
    // Reduce the previous step counter as user moves to next step.
    self.updatePreviousStepCounterValue(-1);
    self.trigger(self.data);
  }

  setPreviousActivityData(data) {
    const self = this;
    let isPresent = false;
    const currentActiveStep = {
      stepNumber: self.data.stateData.activeStep,
      subStepNumber: self.data.stateData.activeSubStep,
      isForm: self.data.stateData.currentActiveStep.isForm,
      isDocumentsToSign:
        self.data.stateData.currentActiveStep.isDocumentsToSign,
      isRecap: self.data.stateData.currentActiveStep.isRecap,
      isConvPreuv: self.data.stateData.currentActiveStep.isConvPreuv,
      isOTP: self.data.stateData.currentActiveStep.isOTP,
      isDocumentsToSignAlreadyDisplayed:
        self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed,
      isRecapAlreadyDisplayed:
        self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed,
      isConvPreuvAlreadyDisplayed:
        self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed,
      clauses: data.consent.clauses,
    };
    let previousActiveStepArray;
    if (self.data.stateData.previousActiveStep.size > 0) {
      previousActiveStepArray = self.data.stateData.previousActiveStep.get(
        self.data.stateData.activeStep
      );
      if (previousActiveStepArray) {
        previousActiveStepArray.forEach((step) => {
          if (
            currentActiveStep.stepNumber === step.stepNumber &&
            currentActiveStep.subStepNumber === step.subStepNumber
          ) {
            isPresent = true;
          }
        });
      } else {
        previousActiveStepArray = [];
      }
    } else {
      previousActiveStepArray = [];
    }
    if (!isPresent) {
      previousActiveStepArray.push(currentActiveStep);
      self.data.stateData.previousActiveStep.set(
        self.data.stateData.activeStep,
        previousActiveStepArray
      );
    }
    self.trigger(self.data);
  }

  getStepFromState(step, subStep) {
    const self = this;
    let previousStep;
    if (self.data.stateData.previousActiveStep.size > 0) {
      previousStep = self.data.stateData.previousActiveStep.get(step)[subStep];
    }
    return previousStep;
  }

  updateStepNumber() {
    const self = this;
    if (
      !self.isPreviousStepSameAsCurrentOne(
        self.getStepFromState(
          self.data.stateData.activeStep,
          self.data.stateData.activeSubStep
        )
      )
    ) {
      self.data.stateData.activeStep++;
      self.data.stateData.activeSubStep = 0;
    } else {
      self.data.stateData.activeSubStep++;
    }
    self.trigger(self.data);
  }

  onNext(object) {
    const self = this;
    if (self.data.stateData.currentActiveStep.isOTP) {
      self.onCompleteSignTask();
    } else {
      self.updateCurrentDisplayPage(object);
      self.updateStepNumber();
      self.trigger(self.data);
    }
  }

  setClauses(activityType, clausesToApply) {
    const self = this;
    if (activityType === "consultation") {
      self.data.userAccessInfo.consent.clauses = clausesToApply;
      self.updateCurrentDisplayPage(self.data.userAccessInfo);
    } else {
      self.data.signatureUserAccessInfo.consent.clauses = clausesToApply;
      self.updateCurrentDisplayPage(self.data.signatureUserAccessInfo);
    }
    self.trigger(self.data);
  }

  onPrevious() {
    const self = this;
    self.updatePreviousStepCounterValue(1);
    if (self.data.stateData.activeSubStep > 0) {
      self.data.stateData.activeSubStep--;
      self.trigger(self.data);
    } else if (self.data.stateData.activeStep > 0) {
      self.data.stateData.activeStep--;
      self.data.stateData.activeSubStep =
        self.data.stateData.previousActiveStep.get(
          self.data.stateData.activeStep
        ).length - 1;
      self.trigger(self.data);
    }
    const previousStep = self.getStepFromState(
      self.data.stateData.activeStep,
      self.data.stateData.activeSubStep
    );
    self.data.userAccessInfo.consent &&
      self.data.signatureUserAccessInfo.consent === undefined
      ? self.setClauses("consultation", previousStep.clauses)
      : self.setClauses("signature_session", previousStep.clauses);
    self.data.stateData.currentActiveStep.isForm = previousStep.isForm;
    self.data.stateData.currentActiveStep.isDocumentsToSign =
      previousStep.isDocumentsToSign;
    self.data.stateData.currentActiveStep.isRecap = previousStep.isRecap;
    self.data.stateData.currentActiveStep.isConvPreuv =
      previousStep.isConvPreuv;
    self.data.stateData.currentActiveStep.isOTP = previousStep.isOTP;
    self.data.stateData.currentActiveStep.isDocumentsToSignAlreadyDisplayed =
      previousStep.isDocumentsToSignAlreadyDisplayed;
    self.data.stateData.currentActiveStep.isRecapAlreadyDisplayed =
      previousStep.isRecapAlreadyDisplayed;
    self.data.stateData.currentActiveStep.isConvPreuvAlreadyDisplayed =
      previousStep.isConvPreuvAlreadyDisplayed;
    if (previousStep.isConvPreuv) {
      self.onSetNextButtonActiveState(false);
    } else {
      self.onSetNextButtonActiveState(true);
    }
    self.trigger(self.data);
  }

  onClear() {
    this.data.errors = [];
    this.data.success = [];
    this.trigger(this.data);
  }

  onDownloadDocument(uri) {
    uri = uri.replace("/index.json", "");
    const self = this;
    fetch(APIUtils("downloadDocument").replace("INDEX_URL", uri), {
      method: "get",
      headers: { "Content-Type": "application/pdf" },
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          toast.error(
            self.translationContent().downloadDocumentError,
            self.toastParameters()
          );
          self.trigger(self.data);
        }
      })
      .then(function (data) {
        const byteCharacters = atob(data.documentbyte);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byte = new Uint8Array(byteNumbers);
        var blob = new Blob([byte], { type: "application/pdf" });
        if (navigator.appVersion.toString().indexOf(".NET") > 0)
          window.navigator.msSaveOrOpenBlob(blob, data.fileName);
        else {
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("download", data.fileName);
          link.setAttribute("href", url);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        toast.error(
          self.translationContent().downloadError,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }

  onCreateChannel(channelPayload) {
    const self = this;
    self
      .createChannelAPICall(
        this.data.signatureUserAccessInfo.access_id,
        "create_channel",
        channelPayload
      )
      .then(function (data) {
        if (data) {
          self.data.signatureChannel = data;
          getDocumentsIndexUrl(
            self.data.stateData,
            getSortedDocs(self.data.signatureUserAccessInfo.documents),
            data.scid,
            data.sck,
            self
          );
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        toast.error(
          self.translationContent().serverNotReachableChannel,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  onDocConsentProvided(docConsentProvidedText, docName) {
    const prev = this.data.stateData.docConsentProvided.indexOf(docName) !== -1;
    if (!prev) {
      const payload = { text: docConsentProvidedText };
      const self = this;
      self
        .saveConsent(
          self.data.signatureUserAccessInfo.access_id,
          self.data.signatureChannel.scid,
          self.data.signatureChannel.sck,
          "docConsentProvided",
          payload,
          docName
        )
        .then(function (data) {
          if (data) {
            if (
              self.data.stateData.docConsentProvided.indexOf(docName) === -1
            ) {
              self.data.stateData.docConsentProvided.push(docName);
            } else {
              self.data.stateData.docConsentProvided = self.arrayRemove(
                self.data.stateData.docConsentProvided,
                docName
              );
            }
            self.trigger(self.data);
          }
        })
        .catch((error) => {
          self.data.stateData.docConsentProvided = self.arrayRemove(
            self.data.stateData.docConsentProvided,
            docName
          );
          self.serverNotReachableToast();
          self.trigger(self.data);
        });
    } else {
      this.data.stateData.docConsentProvided = this.arrayRemove(
        this.data.stateData.docConsentProvided,
        docName
      );

      this.trigger(this.data);
    }
  }

  onTcuConsentProvided(tcuConsentProvidedText) {
    const prev = this.data.stateData.tcuConsentProvided;
    if (!prev) {
      const payload = { text: tcuConsentProvidedText };
      const self = this;
      self
        .saveConsent(
          self.data.signatureUserAccessInfo.access_id,
          self.data.signatureChannel.scid,
          self.data.signatureChannel.sck,
          "tcuConsentProvided",
          payload,
          ""
        )
        .then(function (data) {
          if (data) {
            self.data.stateData.tcuConsentProvided =
              !self.data.stateData.tcuConsentProvided;
            self.trigger(self.data);
          }
        })
        .catch((error) => {
          self.data.stateData.tcuConsentProvided = false;
          self.serverNotReachableToast();
          self.trigger(self.data);
        });
    } else {
      this.data.stateData.tcuConsentProvided = false;
      this.trigger(this.data);
    }
  }

  otpConsentProvided(otpConsentProvidedText) {
    this.data.stateData.otpConsentProvided = false;
    const payload = { text: otpConsentProvidedText };
    const self = this;
    self
      .saveConsent(
        self.data.signatureUserAccessInfo.access_id,
        self.data.signatureChannel.scid,
        self.data.signatureChannel.sck,
        "otpConsentProvided",
        payload,
        ""
      )
      .then(function (data) {
        if (data) {
          self.data.stateData.otpConsentProvided = true;
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.data.stateData.otpConsentProvided = false;
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  onGenerateCertificate() {
    const self = this;
    self
      .generateCertificateAPICall(
        self.data.signatureUserAccessInfo.access_id,
        self.data.signatureChannel.scid,
        self.data.signatureChannel.sck,
        "generate_certificate"
      )
      .then(function (data) {
        if (data) {
          self.data.certificate = data;
          //Skip sending otp if indian number
          // if(!(self.data.signatureUserAccessInfo.signatory.phone.substring(0,4)==='0091')){
          self.onSendOTP();
          // }
        }
      })
      .catch((error) => {
        toast.error(
          self.translationContent().serverNotReachableCertificate,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }

  onResetOTPCounter() {
    this.data.stateData.otpCounter = 0;
    this.trigger(this.data);
  }

  onSendOTP() {
    const self = this;
    self.data.stateData.sendOtpStatus = "";
    self.data.stateData.otpCounter++;
    const payload = {
      isNumber: true,
      signatoryId: self.data.tokenResponse.signatoryRef,
      otpMode: self.data.tokenResponse.signatoryOtpMode
        ? self.data.tokenResponse.signatoryOtpMode
        : process.env.REACT_APP_OTP_MODE,
    };

    fetch(APIUtils("sendOTP"), {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          toast.error(
            self.translationContent().sendOTPError,
            self.toastParameters()
          );
          const docPayload = self.getProofDocPayload(
            self.data.tokenResponse.transactionId,
            "ERROR" + response.status,
            "SEND_OTP_FAIL"
          );
          self.data.stateData.otpCounter > 1 &&
            toast.error(
              self.translationContent().sendOTPError,
              self.toastParameters()
            );
          self.onOtpProof(docPayload);
        }
      })
      .then(function (data) {
        if (data) {
          self.data.isignSendOtpResponse = data;
          self.data.stateData.signReadyToComplete = false;
          const docPayload = self.getProofDocPayload(
            self.data.tokenResponse.transactionId,
            data.codeId,
            "SEND_OTP_SUCCESS"
          );
          self.data.stateData.otpCounter > 1 &&
            toast.success(
              self.translationContent().sendOTPSuccess,
              self.toastParameters()
            );
          self.onOtpProof(docPayload);
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.data.stateData.sendOtpStatus = "SEND_OTP_FAIL";
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  onValidateOTP() {
    const self = this;
    self.data.stateData.validateOtpStatus = "";
    const otpProvided = self.data.stateData.otpProvided;

    fetch(
      APIUtils("validateOTP")
        .replace("CODE_ID", self.data.isignSendOtpResponse.codeId)
        .replace("OTP", otpProvided)
        .replace("SIGNATORY_LOGIN", self.data.tokenResponse.signatoryRef),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(function (response) {
        if (response.ok) {
          toast.success(
            self.translationContent().validateOTPSuccess,
            self.toastParameters()
          );
          return response.json();
        } else {
          self.data.validateOtpStatus = "VALIDATE_OTP_INVALID";
          if (response.status === 422) {
            toast.error(
              self.translationContent().expiredOTPError,
              self.toastParameters()
            );
          } else {
            toast.error(
              self.translationContent().invalidOTPError,
              self.toastParameters()
            );
          }
          const payload = self.getProofDocPayload(
            self.data.tokenResponse.transactionId,
            self.data.isignSendOtpResponse.codeId,
            "VALIDATE_OTP_FAIL"
          );
          self.onOtpProof(payload);
        }
      })
      .then(function (data) {
        if (data) {
          self.data.isignValidateOtpResponse = data;
          self.data.validateOtpStatus = "VALIDATE_OTP_SUCCESS";
          self.onAcceptCertificate();
        }
      })
      .catch((error) => {
        self.data.validateOtpStatus = "VALIDATE_OTP_FAIL";
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  getProofDocPayload(txnId, authId, status) {
    const self = this;
    const authenticationId = !authId ? "otp_session_error" : authId;
    const ip = self.data.systemIp ? self.data.systemIp : "";
    const otpMode = self.data.tokenResponse.signatoryOtpMode
      ? self.data.tokenResponse.signatoryOtpMode
      : process.env.REACT_APP_OTP_MODE;
    const destination =
      otpMode === process.env.REACT_APP_OTP_MODE
        ? self.data.signatureUserAccessInfo.signatory.phone
        : self.data.signatureUserAccessInfo.signatory.email;

    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + " " + time;
    return {
      transactionId: txnId,
      authenticationId: authenticationId,
      authenticationIp: ip,
      authenticationStatus: status,
      authenticationTimestamp: dateTime,
      authenticationType: otpMode,
      destination: destination,
    };
  }

  onOtpProof(authdata) {
    const self = this;
    const payload = authdata;
    fetch(
      APIUtils("proofOTP").replace(
        "SIGNATORY_LOGIN",
        self.data.tokenResponse.signatoryRef
      ),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then(function (response) {
        if (response.ok) {
          return "success";
        } else {
          toast.error(
            self.translationContent().proofOTPError,
            self.toastParameters()
          );
          self.trigger(self.data);
        }
      })
      .then(function (data) {
        if (data === "success") {
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        console.log("Server not reachable:Error in otp proof ");
        self.trigger(self.data);
      });
  }

  onAcceptCertificate() {
    const self = this;
    const payload = { consent: "accept" };
    self
      .acceptCertificateAPICall(
        self.data.signatureUserAccessInfo.access_id,
        self.data.signatureChannel.scid,
        self.data.signatureChannel.sck,
        payload
      )
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          self.onRecreateChannel();
          self.trigger(self.data);
        }
      })
      .then(function (data) {
        self.getDocumentProof(data);
      })
      .catch((error) => {
        toast.error(
          self.translationContent().serverNotReachableAcceptCertificate,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }

  onCompleteSignTask() {
    const self = this;
    fetch(
      APIUtils("completeSignTask").replace(
        "TASK_ID",
        Stepper.getActivityParameter(
          this.data.signatureActivity,
          "signature_session",
          "identifier"
        )
      ),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-DICTAO-DTP-SCID": this.data.signatureChannel.scid,
          "X-DICTAO-DTP-SCK": this.data.signatureChannel.sck,
        },
      }
    )
      .then(function (response) {
        if (response.ok) {
          return "success";
        } else {
          toast.error(
            self.translationContent().signatureInvalid,
            self.toastParameters()
          );
          self.trigger(self.data);
        }
      })
      .then(function (data) {
        if (data === "success") {
          self.data.stateData.finished = true;
          toast.success(
            self.translationContent().signatureSuccessfull,
            self.toastParameters()
          );
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.serverNotReachableToast();
        self.trigger(self.data);
      });
  }

  onDisplayDocument(docName) {
    const pages = this.data.stateData.docIndexUrlMap.get(docName);
    this.data.stateData.firstLargeFileDisableDisplay = false;
    this.data.stateData.docImage = addAllPagesToArray(pages);
    this.trigger(this.data);
  }

  displayAppuiDocMessage() {
    this.data.stateData.docImage = [];
    this.data.stateData.firstLargeFileDisableDisplay = true;
    this.trigger(this.data);
  }

  onDownloadSignedFilesZip(idRef) {
    const downloadSignedFilesURL = APIUtils("downloadSignedFileZip").replace(
      "DOC_TOKEN",
      idRef
    );
    fetch(downloadSignedFilesURL, {
      method: "get",
      headers: {
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => {
        if (response.ok) {
          if (response.url && response.url.length > 0) {
            window.location.href = response.url;
          } else {
            //when url is not present in response, for Firefox < 32, Chrome < 37, Safari, or IE
            window.location.href = downloadSignedFilesURL;
          }
        } else {
          alert(this.translationContent().downloadSignedFileError);
        }
      })
      .catch((error) => {
        alert(this.translationContent().fileDownloadError);
      });
  }

  onSetIPAddress() {
    const self = this;
    fetch("https://api.ipify.org?format=json", {
      method: "get",
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
      })
      .then(function (data) {
        if (data) {
          self.data.systemIp = data.ip;
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        self.trigger(self.data);
      });
  }

  onRecreateChannel() {
    const self = this;
    self.data.signatureChannel = {};
    const channelPayload = { viewId: "dictao-dtp-bpm_4.2.8.0", timeout: 3600 };
    self
      .createChannelAPICall(
        this.data.signatureUserAccessInfo.access_id,
        "recreate_channel",
        channelPayload
      )
      .then(function (data) {
        if (data) {
          self.data.signingChannel = data;
          self.data.signatureChannel = data;
          self.onRegenerateCertificate();
          self.trigger(self.data);
        }
      })
      .catch((error) => {
        toast.error(
          self.translationContent().serverNotReachableRecreateChannel,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }

  onRegenerateCertificate() {
    const self = this;
    self
      .generateCertificateAPICall(
        self.data.signatureUserAccessInfo.access_id,
        self.data.signingChannel.scid,
        self.data.signingChannel.sck,
        "regenerate_certificate"
      )
      .then(function (data) {
        if (data) {
          self.data.certificate = data;
          self.onReacceptCertificate();
        }
      })
      .catch((error) => {
        toast.error(
          self.translationContent().serverNotReachableRegenerateCertificate,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }

  getDocumentProof = (data) => {
    const self = this;
    if (data) {
      self.data.certificate = data;
      self.data.stateData.signReadyToComplete =
        !self.data.stateData.signReadyToComplete;
      const docPayload = self.getProofDocPayload(
        self.data.tokenResponse.transactionId,
        self.data.isignSendOtpResponse.codeId,
        "VALIDATE_OTP_SUCCESS"
      );
      self.onOtpProof(docPayload);
    }
  };

  onReacceptCertificate() {
    const self = this;
    const payload = { consent: "accept" };
    self
      .acceptCertificateAPICall(
        self.data.signatureUserAccessInfo.access_id,
        self.data.signingChannel.scid,
        self.data.signingChannel.sck,
        payload
      )
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          self.data.stateData.acceptCertificateFail = true;
          toast.error(
            self.translationContent().serverNotReachableReacceptCertificateRAC,
            self.toastParameters()
          );
          self.trigger(self.data);
        }
      })
      .then(function (data) {
        self.getDocumentProof(data);
      })
      .catch((error) => {
        toast.error(
          self.translationContent().serverNotReachableReacceptCertificate,
          self.toastParameters()
        );
        self.trigger(self.data);
      });
  }
}
