import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

function LargeFileViewer() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <Grid>
        <br />
        <br />
        <Typography variant={"h6"}>{t("largeFileMessage")}</Typography>
      </Grid>
    </div>
  );
}
export default LargeFileViewer;
