import React from "react";
import Grid from "@material-ui/core/Grid";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    height: "100%",
  },
  tabComponent: {
    backgroundColor: "#f8f9fa",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  content: {
    textAlign: "justify",
    fontSize: "14px",
    padding: "16px 0px 16px 0px",
    minHeight: "60vh",
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  h1Style: {
    fontSize: "42px",
    margin: "24px 20px 24px 20px",
    textAlign: "left",
  },
  signSuccededIcon: {
    marginTop: theme.spacing(4),
    color: "#02cc53",
    width: "auto",
    height: "100px",
  },
  contractCancelledIcon: {
    marginTop: theme.spacing(4),
    color: "#ff8b00",
    width: "auto",
    height: "100px",
  },
  contractExpiredIcon: {
    marginTop: theme.spacing(4),
    color: "#E80000",
    width: "auto",
    height: "100px",
  },
});

function FinalPage(props) {
  const { t } = useTranslation();
  const { classes } = props;
  const finalPageContent = () => {
    const pageState = {};
    if (props.data.stateData.tokenNotTranslated) {
      pageState["title"] = "finalPageTitles.tokenError";
      pageState["finalMessage"] = t("contractLoginError");
    } else if (props.data.stateData.finished) {
      pageState["title"] = "finalPageTitles.signed";
      pageState["finalMessage"] = t("finalMessage");
    } else if (props.data.stateData.signAlreadyCompleted) {
      pageState["title"] = "finalPageTitles.signed";
      pageState["finalMessage"] = t("signAlreadyCompletedMessage");
    } else if (props.data.stateData.contractCancelled) {
      pageState["title"] = "finalPageTitles.cancelled";
      pageState["finalMessage"] = t("contractCancelled");
    } else if (props.data.stateData.contractExpired) {
      pageState["title"] = "finalPageTitles.expired";
      pageState["finalMessage"] = t("contractExpired");
    } else if (props.data.stateData.contractInError) {
      pageState["title"] = "finalPageTitles.error";
      pageState["finalMessage"] = t("contractInError");
    } else {
      pageState["title"] = "finalPageTitles.loadingContractDataError";
      pageState["finalMessage"] = t("contractLoadingDataError");
    }
    return pageState;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
      <Grid item xs={12} sm={10} lg={10} xl={8}>
        <h1 className={classes.h1Style}>{t(finalPageContent().title)}</h1>
        <div className={classes.tabComponent}>
          <Grid item xs={12}>
            {(props.data.stateData.finished ||
              props.data.stateData.signAlreadyCompleted) && (
              <CheckCircleOutlineIcon className={classes.signSuccededIcon} />
            )}
            {props.data.stateData.contractCancelled && (
              <CancelIcon className={classes.contractCancelledIcon} />
            )}
            {props.data.stateData.loadingContractDataFailed && (
              <CancelIcon className={classes.contractExpiredIcon} />
            )}
            {props.data.stateData.contractExpired && (
              <WatchLaterIcon className={classes.contractExpiredIcon} />
            )}
            {props.data.stateData.contractInError && (
              <ErrorIcon className={classes.contractExpiredIcon} />
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.content}>
              {finalPageContent().finalMessage}
            </div>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={"auto"} sm={1} lg={1} xl={2}></Grid>
    </Grid>
  );
}
export default withStyles(styles)(FinalPage);
