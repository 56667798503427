import React from "react";
import Grid from "@material-ui/core/Grid";
import { getAppUIClauseByLang } from "../../services/consentUtils";
import Document from "./Document";
import renderHTML from "react-render-html";
import { useTranslation } from "react-i18next";
const actions = require("../../actions/Stepper");

function DocumentDownloadSection(props) {
  const {
    classes,
    signatureUserAccessInfo,
    langState,
    appuiDocs,
    signingDoc,
    setAllDocumentsReadOrViewedAction,
    displayActionComplete,
    setCurrentDoc,
    completeDocArrayState,
    stateBooleans,
    scrollToTop,
    storeFunctions,
  } = props;
  const { t } = useTranslation();

  const documentsToDownload = (
    <>
      {appuiDocs.map((item, index) => {
        const isSigningDoc = signingDoc.filter((appuiDoc) => appuiDoc === item);
        setAllDocumentsReadOrViewedAction();
        return (
          <>
            <Grid
              item
              xs={12}
              className={classes.downloadDocument}
              key={`appuiDocsGrid${item.name}`}
            >
              <Document
                key={`appuiDocs${item.name}`}
                forSign={isSigningDoc?.length}
                ext={item.type}
                name={item.name}
                action={() => {
                  displayActionComplete(
                    item,
                    setCurrentDoc,
                    completeDocArrayState,
                    stateBooleans,
                    storeFunctions,
                    scrollToTop
                  );
                }}
                actualItem={item}
                downloadAction={actions.downloadDocument.bind(
                  null,
                  item.index_url
                )}
                displayAction={actions.downloadDocument.bind(
                  null,
                  item.index_url
                )}
              />
            </Grid>
          </>
        );
      })}
    </>
  );

  return (
    <Grid xs={12} className={classes.container} item>
      <Grid xs={12} className={classes.subTitle} item>
        {t("contractualDocumentation")}
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {signatureUserAccessInfo.consent.clauses.filter(
          (i) => i.document_type_ref === "ISN2-Appui"
        ).length > 0 ? (
          <>
            <div>
              {renderHTML(
                getAppUIClauseByLang(signatureUserAccessInfo, langState)
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </Grid>
      {documentsToDownload}
    </Grid>
  );
}

export default DocumentDownloadSection;
