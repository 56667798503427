import React from "react";
import Grid from "@material-ui/core/Grid";
import LargeFileViewer from "../LargeFileViewer";
import LoadingPage from "../LoadingPage";
import { LazyLoadImage } from "react-lazy-load-image-component";

function DocumentImage(props) {
  const {
    classes,
    docImage,
    firstLargeFileDisableDisplay,
    docToSignRef,
    docImageRef,
    onScroll,
    listInnerRef,
    data,
    scrollPosition,
    setAreDocumentsLoaded,
    updateDocumentArray,
    currentDoc,
    areDocumentsLoaded,
    completeDocArrayState,
    areAllDocumentsReadOrViewedState,
    isRead,
    storeFunctions,
  } = props;

  if (docImage.length === 0 && firstLargeFileDisableDisplay) {
    return (
      <Grid item xs={12} ref={docToSignRef}>
        <LargeFileViewer />
      </Grid>
    );
  } else if (docImage.length === 0 && !firstLargeFileDisableDisplay) {
    return (
      <Grid item xs={12} ref={docToSignRef}>
        <LoadingPage data={data} isDocument={true} />
      </Grid>
    );
  } else if (docImage.length > 0) {
    return (
      <Grid item xs={12} ref={docToSignRef}>
        <div id="div1" className={classes.container}>
          <div
            id="div2"
            className={classes.scrolldiv}
            onScroll={onScroll}
            ref={listInnerRef}
            tabIndex="0"
            aria-label={currentDoc.name}
          >
            <div className={"doc-image-div-container"}>
              {docImage.map((image) => (
                <LazyLoadImage
                  style={{ width: "100%", height: "100%" }}
                  ref={docImageRef}
                  key={image.key}
                  height={image.height}
                  width={image.width * 0.6}
                  scrollPosition={scrollPosition}
                  src={image.src}
                  alt={currentDoc.name}
                  afterLoad={() => {
                    setAreDocumentsLoaded(true);
                    updateDocumentArray(
                      currentDoc,
                      true,
                      areDocumentsLoaded,
                      completeDocArrayState,
                      areAllDocumentsReadOrViewedState,
                      isRead,
                      storeFunctions
                    );
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}

export default DocumentImage;
