import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@dex/text-field";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";

const actions = require("../actions/Stepper");

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonLeft: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  buttonRight: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "right",
  },
  content: {
    /*marginTop: theme.spacing(2),*/
    margin: theme.spacing(2),
    alignItems: "center",
    marginBottom: theme.spacing(2),
    textAlign: "justify",
  },
});

function UserConsent(props) {
  const [otpValue, setOtpValue] = useState("");

  useEffect(() => {
    actions.generateCertificate();
    actions.resetOTPCounter();
  }, []);

  const { classes } = props;
  const { t } = useTranslation();

  const otpMode = props.data.tokenResponse.signatoryOtpMode
    ? props.data.tokenResponse.signatoryOtpMode
    : process.env.REACT_APP_OTP_MODE;
  const mailReceived =
    t("mailReceivedPart1") +
    otpMode +
    t("mailReceivedPart11") +
    t("mailReceivedPart2");
  const enterTheCodeLabel = t("enterTheCodeLabel");

  const handleChange = (event) => {
    const trimmedValue = event.target.value.trim();
    event.target.value = trimmedValue;
    props.data.stateData.otpProvided = trimmedValue;
    setOtpValue(trimmedValue);
  };

  const validateOTPButton = document.getElementById("validateOTP");

  return (
    <Grid container>
      <Grid item xs={12} className={classes.content}>
        <div>{mailReceived}</div>
      </Grid>
      <Grid item xs={2} sm={3} md={3} lg={4} xl={4}></Grid>
      <Grid item xs={8} sm={6} md={6} lg={4} xl={4}>
        <TextField
          label={enterTheCodeLabel}
          required={true}
          placeholder={"ie.: 12345678"}
          direction={"column"}
          readOnly={false}
          id={"outlined-required"}
          name={"outlined-required"}
          onChange={(event) => handleChange(event)}
          fieldColumnsNumber={12}
          autoComplete="off"
          onKeyDown={(event) =>
            event.key === "Enter" &&
            validateOTPButton &&
            validateOTPButton.click()
          }
        />
      </Grid>
      <Grid item xs={2} sm={3} md={3} lg={4} xl={4}></Grid>
      <Grid item xs={2} sm={3} md={3} lg={4} xl={4}></Grid>
      <Grid
        item
        xs={4}
        sm={3}
        md={3}
        lg={2}
        xl={2}
        className={classes.buttonLeft}
      >
        <button
          className=" btn btn-flat-primary"
          style={{ borderColor: "black" }}
          onClick={actions.sendOTP}
        >
          {t("resendOTP")}
        </button>
      </Grid>
      <Grid
        item
        xs={4}
        sm={3}
        md={3}
        lg={2}
        xl={2}
        className={classes.buttonRight}
      >
        <button
          className=" btn btn-primary"
          id="validateOTP"
          disabled={props.data.stateData.signReadyToComplete || otpValue === ""}
          style={{ borderColor: "black" }}
          onClick={actions.validateOTP}
        >
          {t("validateOTP")}
        </button>
      </Grid>
      <Grid item xs={2} sm={3} md={3} lg={4} xl={4}></Grid>
    </Grid>
  );
}

export default withStyles(styles)(UserConsent);
