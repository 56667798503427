import React from "react";

function Loader() {
  return (
    <div className="App">
      <div>loading...</div>
    </div>
  );
}
export default Loader;
