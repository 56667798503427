import React from "react";
import Grid from "@material-ui/core/Grid";

function DocumentAppuiPage(props) {
  const { classes, isExpanded, downloadSectionElement, imageElement } = props;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.document}>
        {isExpanded ? downloadSectionElement : imageElement}
      </Grid>
    </Grid>
  );
}

export default DocumentAppuiPage;
