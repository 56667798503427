import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Navbar, NavbarBrand } from "reactstrap";
import withStyles from "@material-ui/core/styles/withStyles";
import { AccountCenter } from "@dex/react-account-center";
import sgLogoMini from "./assets/images/mini-brand.png";

const styles = (theme) => ({
  mainHeader: {
    alignItems: "center",
    marginBottom: theme.spacing(6),
  },
  logoImage: {
    marginTop: "20px",
    alignItems: "center",
  },
});

function Header(props) {
  const language = useStoreState((state) => state.userInfo.lang);
  const user = useStoreState((state) => state.userInfo.user);

  const setLanguage = useStoreActions(
    (actions) => actions.userInfo.setLanguage
  );
  const setUser = useStoreActions((actions) => actions.userInfo.setUser);

  useEffect(() => {
    setUser({ name: props.name, mail: props.email });
  }, [setUser, props.name, props.email]);

  const skipNav = () => {
    const container = document.getElementById("main");
    if (container) {
      container.tabIndex = 0;
      container.focus();
      setTimeout(() => container.removeAttribute("tabindex"), 1000);
    }
  };

  return (
    <Navbar
      fixed="top"
      light={true}
      expand="lg"
      className="container bg-white d-flex justify-content-between align-content-center"
      style={{ width: "100%", zIndex: "200" }}
    >
      <button onClick={skipNav} className="btn btn-lg btn-info showOnFocus">
        Skip to content
      </button>
      <NavbarBrand>
        <img src={sgLogoMini} alt="Logo Societe Generale" className="d-block" />
      </NavbarBrand>
      <div className="d-flex align-items-center order-3 order-lg-4">
        <AccountCenter
          style={{ overflow: "hidden", position: "fixed", top: 0 }}
          locale={language}
          onChangeLocale={(lang) => {
            setLanguage(lang);
            props.setLanguage(lang);
          }}
          user={{ name: user.name, mail: user.mail }}
        />
        <h4 className="ml-4" style={{ textAlign: "left" }}>
          <span style={{ color: "red" }}>i</span>Sign
          <br />
          <div style={{ fontSize: "14px" }}>
            Signing <span style={{ color: "red" }}>I</span>nterface
          </div>
        </h4>
        <br />
      </div>
    </Navbar>
  );
}

export default withStyles(styles)(Header);
