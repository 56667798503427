import React, { useState } from "react";
import LoadingPage from "./LoadingPage";
import DocumentsStepper from "./Documents/DocumentsStepper";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  stepperMargin: {
    margin: "20px 10px 0px 10px",
  },
});

function Stepper(props) {
  const {
    classes,
    signingDocsHeight,
    documentIconsArray,
    notAppuiDocsSteps,
    color,
    docImageRef,
    onClick,
    data,
  } = props;

  const [hasBeenLoaded, setHasBeenLoaded] = useState(false);

  !hasBeenLoaded && docImageRef.current && setHasBeenLoaded(true);

  const stepper = (
    <Grid container>
      <Grid item xs={12} style={{ overflowX: "auto" }}>
        <DocumentsStepper
          steps={notAppuiDocsSteps}
          color={color}
          isVertical={true}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );

  const innerComponent = (
    <>
      {hasBeenLoaded ? (
        <>
          {stepper}
          <div className={"bg-lvl1"} style={{ width: "75px", zIndex: "10" }}>
            {documentIconsArray}
          </div>
        </>
      ) : (
        <Grid
          style={{
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <LoadingPage data={data} isDocumentList={true} />
        </Grid>
      )}
    </>
  );

  const displayStepper = (
    <>
      {documentIconsArray.length > 0 ? (
        <div
          className={classes.stepperMargin}
          style={{ height: signingDocsHeight, display: "flex" }}
        >
          {innerComponent}
        </div>
      ) : (
        <>{stepper}</>
      )}
    </>
  );

  return <>{displayStepper}</>;
}

export default withStyles(styles)(Stepper);
