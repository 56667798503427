const getRank = (clause) => {
  if (clause.tag[0].indexOf("order:") !== -1) {
    return clause.tag[0].split("order:")[1];
  } else {
    return clause.tag[1].split("order:")[1];
  }
};

const isProvidedType = (clauseAsText, type) => {
  return (
    clauseAsText.tag.indexOf(`type:${type}`) !== -1 ||
    clauseAsText.clause.indexOf("type='checkbox'") !== -1
  );
};

const getSortedClauses = (clauses) => {
  return clauses.sort((a, b) => {
    const aRank = getRank(a);
    const bRank = getRank(b);
    return aRank - bRank;
  });
};

export const getSortedClausesAsTextBlock = (clauses) => {
  const sortedClauses = getSortedClauses(clauses);

  let sortedClausesAsText = "";
  sortedClauses.forEach((clauseAsText, index) => {
    sortedClausesAsText = `${sortedClausesAsText}${clauseAsText.clause}`;
  });
  return sortedClausesAsText;
};
export const getConvPreuvClauses = (clauses) => {
  const sortedClauses = getSortedClauses(clauses);

  let sortedClausesAsText = "";
  sortedClauses.forEach((clauseAsText, index) => {
    if (!clauseAsText.clause.includes("<li><input")) {
      sortedClausesAsText = `${sortedClausesAsText}${clauseAsText.clause}`;
    }
  });
  return sortedClausesAsText;
};

export const getSortedClausesAsText = (clauses, splicer) => {
  const sortedClauses = getSortedClauses(clauses);

  let sortedClausesAsTextPart1 = "";
  let sortedClausesAsTextPart2 = "";
  sortedClauses.forEach((clauseAsText, index) => {
    if (!isProvidedType(clauseAsText, "case_cocher")) {
      // Display all non checkboxes elements before document checkboxes
      if (getRank(clauseAsText) <= splicer) {
        sortedClausesAsTextPart1 = `${sortedClausesAsTextPart1}${clauseAsText.clause}`;
        // Display all non checkboxes elements after document checkboxes
      } else if (getRank(clauseAsText) > splicer) {
        sortedClausesAsTextPart2 = `${sortedClausesAsTextPart2}${clauseAsText.clause}`;
      }
    }
  });
  return { part1: sortedClausesAsTextPart1, part2: sortedClausesAsTextPart2 };
};

export const getConsentValue = (docs) => {
  docs.tag.forEach((tag) => {
    if (tag.indexOf("consent:") !== -1) {
      return tag.split("consent:")[1];
    }
  });
  return null;
};

export const getClauseByLang = (clauses, lang, metadata) => {
  const isClauseByLang =
    clauses.indexOf("[/LANG]") !== -1;
  if (isClauseByLang) {
    let cumulatedClauses = "";
    const clausesByLang = clauses.split("[/LANG]");
    const filteredClauseByLang = clausesByLang.filter(
      (clauseIntl) => clauseIntl.indexOf(`[LANG=${lang.toUpperCase()}]`) >= 0
    );
    filteredClauseByLang.forEach((line) => {
      cumulatedClauses = `${cumulatedClauses}${line}`;
    });

    const sortedClausesAsText = cumulatedClauses.split(
      `[LANG=${lang.toUpperCase()}]`
    );
    cumulatedClauses = "";
    sortedClausesAsText.forEach((line) => {
      cumulatedClauses = `${cumulatedClauses}${line}`;
    });
    return cumulatedClauses;
  } else {
    return clauses;
  }
};

export const getAppUIClauseByLang = (info, lang) => {
  const filtered = info.consent.clauses.filter(
    (i) => i.document_type_ref === "ISN2-Appui"
  );
  const clauses = filtered[0].clause;
  const isClauseByLang =
    clauses.indexOf("[/LANG]") !== -1;
  if (isClauseByLang) {
    let cumulatedClauses = "";
    const clausesByLang = clauses.split("[/LANG]");
    const filteredClauseByLang = clausesByLang.filter(
      (clauseIntl) => clauseIntl.indexOf(`[LANG=${lang.toUpperCase()}]`) >= 0
    );
    filteredClauseByLang.forEach((line) => {
      cumulatedClauses = `${cumulatedClauses}${line}`;
    });

    const sortedClausesAsText = cumulatedClauses.split(
      `[LANG=${lang.toUpperCase()}]`
    );
    cumulatedClauses = "";
    sortedClausesAsText.forEach((line) => {
      cumulatedClauses = `${cumulatedClauses}${line}`;
    });
    return cumulatedClauses;
  } else {
    return clauses;
  }
};

const getDocRank = (notAppuiDocs) => {
  var r;
  notAppuiDocs.tag.forEach((tag) => {
    if (tag.indexOf("order:") !== -1) {
      r = tag.split("order:")[1];
    }
  });
  return r;
};

export const getSortedDocs = (notAppuiDocs) => {
  return notAppuiDocs.sort((a, b) => {
    const aRank = getDocRank(a);
    const bRank = getDocRank(b);
    return aRank - bRank;
  });
};

/**
 * Return current signatory activiies from activities list.
 * @param {*} activities list of signatories activities
 * @param {*} signatory current signatory
 */
export const getCurrentSignatoryActivities = (activities, signatory) => {
  return activities.filter((activity) => activity.role_ref === signatory);
};

/**
 * Object containing all TCU lines.
 * @param {*} t translation response
 */
export const objectTCU = (t) => [
  { line: t("TCU.line1") },
  { line: t("TCU.line2") },
  { line: t("TCU.line3") },
  { line: t("TCU.line4") },
  { line: t("TCU.line5") },
  { line: t("TCU.line6") },
  { line: t("TCU.line7") },
  { line: t("TCU.line8") },
  { line: t("TCU.line9") },
  { line: t("TCU.line10") },
  { line: t("TCU.line11") },
  { line: t("TCU.line12") },
  { line: t("TCU.line13") },
  { line: t("TCU.line14") },
  { line: t("TCU.line15") },
  { line: t("TCU.line16") },
  { line: t("TCU.line17") },
  { line: t("TCU.line18") },
  { line: t("TCU.line19") },
  { line: t("TCU.line20") },
  { line: t("TCU.line21") },
  { line: t("TCU.line22") },
  { line: t("TCU.line23") },
  { line: t("TCU.line24") },
  { line: t("TCU.line25") },
  { line: t("TCU.line26") },
  { line: t("TCU.line27") },
  { line: t("TCU.line28") },
  { line: t("TCU.line29") },
  { line: t("TCU.line30") },
  { line: t("TCU.line31") },
  { line: t("TCU.line32") },
  { line: t("TCU.line33") },
  { line: t("TCU.line34") },
  { line: t("TCU.line35") },
  { line: t("TCU.line36") },
  { line: t("TCU.line37") },
  { line: t("TCU.line38") },
  { line: t("TCU.line39") },
  { line: t("TCU.line40") },
  { line: t("TCU.line41") },
  { line: t("TCU.line42") },
  { line: t("TCU.line43") },
  { line: t("TCU.line44") },
  { line: t("TCU.line45") },
  { line: t("TCU.line46") },
  { line: t("TCU.line47") },
  { line: t("TCU.line48") },
  { line: t("TCU.line49") },
  { line: t("TCU.line50") },
  { line: t("TCU.line51") },
  { line: t("TCU.line52") },
  { line: t("TCU.line53") },
  { line: t("TCU.line54") },
  { line: t("TCU.line55") },
  { line: t("TCU.line56") },
  { line: t("TCU.line57") },
  { line: t("TCU.line58") },
  { line: t("TCU.line59") },
  { line: t("TCU.line60") },
];
